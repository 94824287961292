import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {AdminItemInterface, ItemInterface, SellItemInterface} from './Interfaces'
import {getRandomIntInclusive} from "../main/admin/itemsTable/ItemsTable";
// @ts-ignore
import aa from "./aa.mp3"
// @ts-ignore
import ss from "./ss.mp3"

export interface mainState {
    value: number
    authorized: boolean,
    currentCategory: number,
    barcodeMap?: {primary: string, secondary: string[]}[]
    message:
        { type: string, message: string, items?: ItemInterface[] }
    user?: {
        name: string,
        username: string,
        token: string
        isAdmin?: boolean,
        address: string[]
        phoneNumber: string,
        orders?: OrderInterface[],
        notPendingOrders?: OrderInterface[]
    },
    needUpdateCart: boolean,
    needUpdateOrders: boolean
    administrative?:{
        key:string,
        secret:string
    },
    cart:{
        items: SellItemInterface[],
        activeItem: string
    },
    items: ItemInterface[] | AdminItemInterface[],
    categories: CategoryInterface[],
    receiptNum: number,
    fastItems: {category: string, id: string, items: {
        id: string,
            name: string,
            price: number,
            barcode: string,
            imageSrc?: string
        }[]}[]
    receiptCache: {
        cash: number,
        cartItems: { name: string, price: number, quantity: number}[],
        uni: string,
        type: number,
        comments: string,
        margin: number
    }[]
}

export interface CategoryInterface {
    _id: string,
    name: string
}

export interface OrderInterface {
    id: string,
    status: string,
    cartItems: {name: string, quantity: number, price: number}[],
    cost: number,
    comments: string,
    date: string,
    supervisor: string,
    deliveryAddress: string,
    ordererName: string,
    supervisorID: string
}

const initialState: mainState = {
    message: {type: "", message: ""},
    value: 0,
    authorized: false,
    currentCategory: -1,
    categories: [],
    items: [],
    cart: {
        items: [],
        activeItem: ""
    },
    needUpdateCart: false,
    needUpdateOrders: false,
    receiptNum: 0,
    fastItems: [],
    receiptCache: []
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        login(state, action: PayloadAction<{username: string, name: string, token: string, isAdmin?: true, address: string[], phoneNumber: string}>) {
            state.authorized = true
            state.user = action.payload
        },
        setSecrets(state, action: PayloadAction<{key:string, secret:string}>) {
            state.administrative = action.payload
        },
        setCategories(state, action: PayloadAction<CategoryInterface[]>) {
            state.categories = action.payload.sort((a, b) => {
                if (a.name>b.name){
                    return 1
                }
                if (a.name<b.name){
                    return -1
                }
                return 0
            })
        },
        setMyOrders(state, action: PayloadAction<OrderInterface[]>) {
            console.log("sdsa: ", action.payload)
            state.user!.orders = action.payload
        },
        setNotPendingOrders(state, action: PayloadAction<OrderInterface[]>) {
            state.user!.notPendingOrders = action.payload
        },
        clear(state) {
            state.value = 0
            state.authorized = false
            state.currentCategory = -1
            state.categories = []
            state.cart = {
                items: [],
                activeItem: ""
            }
            state.user = undefined
            state.needUpdateOrders = false
            state.needUpdateCart = false
            state.receiptNum = 0
        },
        changeCart(state, action: PayloadAction<{id: string, quantity: number}>){
            if(action.payload.id === undefined) return;
            const item = state.cart.items.find(x=>x.id === action.payload.id)
            if(item) {
                item.quantity = action.payload.quantity
                state.cart.activeItem = item.id
                if(item.quantity === 0) {
                    state.cart.items = state.cart.items.filter(x=>x.id !== action.payload.id)
                    return
                }
            }
            else{
                const newItem = state.items.find(x=> x.id === action.payload.id)!
                state.cart.items.push({
                    name: newItem.name,
                    id: newItem.id,
                    quantity: action.payload.quantity,
                    highPrice: newItem.highPrice,
                    lowPrice: newItem.lowPrice,
                    masterPrice: newItem.masterPrice,
                    barcode: newItem.barcode,
                    inBox: newItem.inBox ? newItem.inBox : undefined,
                    inBlock: newItem.inBlock ? newItem.inBlock : undefined,
                })
                state.cart.activeItem = newItem.id
                const stringified = JSON.stringify(newItem.id)

                setTimeout(()=>{
                    const elem = document.getElementById("table" + JSON.parse(stringified))
                    if (elem){
                        elem.scrollIntoView({block: "center", behavior: "smooth"})
                    }else {
                        document.getElementById("tabler-container")!.scrollTo(0, document.getElementById("tabler-container")!.scrollHeight)
                    }
                }, 100)
            }
        },
        clearCart(state){
            state.cart.items = []
        },
        setUpdateCart(state, action: PayloadAction<boolean>){
            state.needUpdateCart = action.payload
        },
        setUpdateOrders(state, action: PayloadAction<boolean>){
            state.needUpdateOrders = action.payload
        },
        setItems(state, action: PayloadAction<ItemInterface[]>){
            state.items = action.payload
            if(state.items[0].lowPrice === undefined){
                state.items.forEach(item=>{
                    item.lowPrice = item.price
                })
            }
            if(state.items[0].highPrice === undefined){
                state.items.forEach(item=>{
                    item.highPrice = item.price
                })
            }
            state.items.forEach(item=>{
                if(item.lowPrice === undefined){
                    state.items.forEach(item=>{
                        item.lowPrice = item.price
                    })
                }
                if(item.highPrice === undefined){
                    item.highPrice = item.price
                }
                if (item.barcode === undefined){
                    item.barcode = ""
                }
            })
            state.items.sort((a, b)=>{
                if (a.name>b.name){
                    return 1
                }
                if (a.name<b.name){
                    return -1
                }
                return 0
            })
            if(state.user && state.user.isAdmin){
                let x:any = []
                state.categories.forEach((cat) => {
                    let ccc = {
                        category: cat.name,
                        id: cat._id,
                        items: []
                    }
                    state.items.forEach((item) => {
                        if(item.category === cat._id) {
                            if(item.inFastCash || item.barcode === "" || item.barcode.startsWith("sh")) {
                                // @ts-ignore
                                ccc.items.push({
                                    id: item.id,
                                    name: item.name,
                                    barcode: item.barcode,
                                    imageSrc: item.imageSrc,
                                    price: item.highPrice,
                                })
                            }
                        }
                    })
                    if (ccc.items.length > 0) {
                        x.push(ccc)
                    }
                })
                state.fastItems = x
            }
        },
        setItem(state, action: PayloadAction<{id: string, item: ItemInterface}>){
            state.items[state.items.findIndex((item=> item.id === action.payload.id))] = action.payload.item
        },
        changeCartByCode(state, action: PayloadAction<{isID: boolean, code: string}>){
            let code = action.payload.code
            const isID = action.payload.isID
            if(isID){
                let foundInCart = false
                state.cart.items.some(item=>{
                    if(item.id === code){
                        foundInCart = true
                        item.quantity++
                        state.cart.activeItem = item.id
                        const stringified = JSON.stringify(item.id)
                        setTimeout(()=>{
                            const elem = document.getElementById("table" + JSON.parse(stringified))
                            if (elem){
                                elem.scrollIntoView({block: "center", behavior: "smooth"})
                            }
                        }, 100)
                        return true
                    }
                })
                if(!foundInCart){
                    let found = state.items.find(item => item.id === code)
                    if (found) {
                        state.cart.items.push({
                            quantity: 1,
                            name: found.name,
                            barcode: found.barcode,
                            id: found.id,
                            masterPrice: found.masterPrice,
                            highPrice: found.highPrice,
                            lowPrice: found.lowPrice,
                            inBox: found.inBox ? found.inBox : undefined,
                            inBlock: found.inBlock ? found.inBlock : undefined,
                        })
                        state.cart.activeItem = found.id
                        const stringified = JSON.stringify(found.id)
                        setTimeout(()=>{
                            const elem = document.getElementById("table" + JSON.parse(stringified))
                            if (elem){
                                elem.scrollIntoView({block: "center", behavior: "smooth"})
                            }
                        }, 100)
                    }
                }
            }
            else{
                state.barcodeMap?.some((barcode)=>{
                    console.log("prim: ", barcode.primary)
                    return barcode.secondary.some(secondary =>{
                        console.log("sec: ", secondary)
                        if (code === secondary){
                            console.log("equal")
                            code = barcode.primary
                            return true
                        }
                    })
                })
                console.log("code is: ", code)
                const found: ItemInterface[] = []
                state.items.forEach(item => {
                    if(item.barcode === code){
                        found.push(item)
                    }
                })
                if(found.length > 1){
                    state.message.type = "cart.manyItems"
                    state.message.message = "Выберите продукт"
                    state.message.items = found
                    const audio = new Audio(ss)
                    audio.play()
                }else if(found.length === 1) {
                    let foundInCart = false
                    state.cart.items.some(item=>{
                        if(item.barcode === code){
                            foundInCart = true
                            item.quantity++
                            state.cart.activeItem = item.id
                            const stringified = JSON.stringify(item.id)
                            setTimeout(()=>{
                                const elem = document.getElementById("table" + JSON.parse(stringified))
                                if (elem){
                                    elem.scrollIntoView({block: "center", behavior: "smooth"})
                                }
                            }, 100)
                            return true
                        }
                    })
                    if(!foundInCart){
                        state.cart.items.push({
                            quantity: 1,
                            name: found[0].name,
                            barcode: found[0].barcode,
                            id: found[0].id,
                            masterPrice: found[0].masterPrice,
                            highPrice: found[0].highPrice,
                            lowPrice: found[0].lowPrice,
                            inBox: found[0].inBox ? found[0].inBox : undefined,
                            inBlock: found[0].inBlock ? found[0].inBlock : undefined
                        })
                        state.cart.activeItem = found[0].id
                        const stringified = JSON.stringify(found[0].id)
                        setTimeout(()=>{
                            const elem = document.getElementById("table" + JSON.parse(stringified))
                            if (elem){
                                elem.scrollIntoView({block: "center", behavior: "smooth"})
                            }
                        }, 100)
                    }
                }
                else {
                    state.message.type = "cart"
                    state.message.message = "Этого товара нет в базе данных"
                    const audio = new Audio(aa)
                    audio.play()
                }
            }

        },
        addUniversalItem(state, action: PayloadAction< {name: string, price: number}>){
            const newItem = {
                name: action.payload.name,
                quantity: 1,
                highPrice: action.payload.price,
                lowPrice: action.payload.price,
                masterPrice: action.payload.price,
                id: "uni_prod"+action.payload.name + getRandomIntInclusive(100000, 999999),
                barcode: "uni_prod"

            }
            state.cart.items.push(newItem)
            state.cart.activeItem = newItem.id
            const stringified = JSON.stringify(newItem.id)
            setTimeout(()=>{
                const elem = document.getElementById("table" + JSON.parse(stringified))
                if (elem){
                    elem.scrollIntoView({block: "center", behavior: "smooth"})
                }else {
                    document.getElementById("tabler-container")!.scrollTo(0, document.getElementById("tabler-container")!.scrollHeight)
                }
            }, 100)
        },
        addCartManyItems(state, action: PayloadAction<string[]>){
            action.payload.forEach(itemID =>{
                const found = state.cart.items.find((cartItem) => cartItem.id === itemID);
                if (found) {
                    found.quantity++
                } else {
                    const item = state.items.find((allItem) => allItem.id === itemID);
                    if (!item){
                        state.message.type = "cart"
                        state.message.message = "Некоторые товары отсутствуют в базе данных"
                        const audio = new Audio(aa)
                        audio.play()
                        return
                    }
                    state.cart.items.push({
                        name: item.name,
                        quantity: 1,
                        highPrice: item.highPrice,
                        masterPrice: item.masterPrice,
                        lowPrice: item.lowPrice,
                        id: itemID,
                        barcode: item.barcode,
                        inBox: item.inBox ? item.inBox : undefined,
                        inBlock: item.inBlock ? item.inBlock : undefined
                    })
                }
                state.cart.activeItem = action.payload[action.payload.length - 1]
                const stringified = JSON.stringify(action.payload[action.payload.length - 1])
                setTimeout(()=>{
                    const elem = document.getElementById("table" + JSON.parse(stringified))
                    if (elem){
                        elem.scrollIntoView({block: "center", behavior: "smooth"})
                    }
                }, 100)
            })
        },
        flushMessage:(state) => {
            state.message = {type: "", message: ""}
        },
        addReceiptNum:(state)=>{
            state.receiptNum++
        },
        changeCartItemPrice:(state, action: PayloadAction<{ id: string, low: number, high: number, master: number, name?: string}>)=>{
            const item = state.cart.items.find(item => item.id === action.payload.id)
            if(!item) return

            item.lowPrice = action.payload.low
            item.highPrice = action.payload.high
            item.masterPrice = action.payload.master
            if(action.payload.name){
                item.name = action.payload.name
            }
        },
        setBarcodeMap(state, action: PayloadAction<{primary: string, secondary: string[]}[]>){
            state.barcodeMap = action.payload
        },
        addToReceiptCache(state, action: PayloadAction<{ cash: number,
            cartItems: { name: string, price: number, quantity: number}[],
            uni: string,
            type: number,
            comments: string,
            margin: number }>){
            state.receiptCache.push(action.payload)
        }
    },

})

// Action creators are generated for each case reducer function
export const {addToReceiptCache, addCartManyItems, setBarcodeMap,changeCartItemPrice, addReceiptNum, addUniversalItem,flushMessage, setItem, changeCartByCode, setItems,setUpdateCart, setUpdateOrders, setNotPendingOrders, setMyOrders, clearCart, login, setSecrets, setCategories, clear, changeCart} = mainSlice.actions

export default mainSlice.reducer