import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Card, FloatButton, Spin, Typography} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import API from "../../API/API";
import {printReceipt} from "./CashPage";
import {useSelector} from "react-redux";

const CashReceipts = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const receiptCache: {cash: number,
        cartItems: { name: string, price: number, quantity: number}[],
        uni: string,
        type: number,
        comments: string,
        margin: number}[] = useSelector((state: any)=>state.main.receiptCache)


    useEffect(() => {
        API.getReceipts()
            .then(data=>{
                console.log("dd: ", data)
                setData(data.data.toReversed())
                setLoading(false)
            })
        if(receiptCache.length > 0){
            // let x = 0
            // receiptCache.forEach(  (receipt)=>{
            //     API.createReceipt(receipt.cartItems, receipt.cash, receipt.uni, receipt.type, receipt.comments)
            //         .then(()=>{
            //             x++
            //         })
            // })
        }
    }, [receiptCache]);
    return (
        <div style={{overflowY: "auto", display: "flex", flexDirection: "column", justifyContent :"center", alignItems: "center"}}>
            <FloatButton type={"primary"} icon={<ArrowLeftOutlined/>} onClick={()=>{
                navigate("/cash", {replace: true})
            }}></FloatButton>
            {
                loading && <div style={{background: "rgba(0,0,0,0.35)",width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}><Spin  size={"large"}></Spin></div>
            }
            {data.filter((receipt :any)=>{ return receipt.cash > 0}).map((receipt: any)=>{
                let cc = 0
                return <Card actions={[<Button onClick={() => {
                    let count = 0
                    let cashItms: any = {}
                    cashItms.items = receipt.cartItems.map((item: any) => {
                        count = count + item.quantity * item.price
                        return {
                            name: item.name,
                            id: item.name,
                            quantity: item.quantity,
                            masterPrice: item.price,
                            highPrice: item.price,
                            lowPrice: item.price,
                            barcode: ""
                        }
                    })
                    printReceipt(cashItms, receipt.cash, 1, count, receipt.createdBy)
                }} type={"primary"}>Распечатать</Button>,
                    <Typography.Text>Принято {receipt.cash + "тг"}</Typography.Text>]} bodyStyle={{overflowY: "auto"}}
                             style={{width: "80%", marginBottom: 30}} title={receipt.uni}>
                    {
                        receipt.cartItems.map((item: any) => {
                            cc = cc + item.quantity * item.price
                            // return <div >{item.price}</div>
                            return <div>{item.name + " " + item.quantity + " * " + item.price + " = " + item.quantity * item.price}</div>
                            // return <div >{item.name + " " + item.quantity + " * " + item.price + " = " + item.quantity * item.price}</div>
                            // return <div >{item.name + " " + item.quantity + " * " + item.price + " = " + item.quantity * item.price}</div>
                        })
                    }
                    <span style={{color: "red"}}>{"Сумма: " + cc + "тг"}</span> <br/>
                    {receipt.margin && receipt.margin > 0 ?
                        <span style={{color: "red"}}>{"Маржа: " + receipt.margin + "тг"}</span> : <></>}

                    {receipt.type !== undefined ?
                        <><br/><span
                            style={{color: "black"}}>{"Тип: " + (receipt.type === 3 ? "Розничный" : receipt.type === 2 ? "Оптовый" : "Супер оптовый")}</span></>
                        : <></>
                    }

                    {receipt.admin !== undefined ?
                        <><br/><span style={{color: "black"}}>{"Кассир: " + receipt.admin}</span></>
                        : <></>
                    }
                    {receipt.comments !== "" ?
                        <><br/><span style={{color: "black"}}>{"Примечания: " + receipt.comments}</span></>
                        : <></>
                    }

                </Card>
            })}
        </div>
    );
};

export default CashReceipts;