import { configureStore } from '@reduxjs/toolkit'
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mainSlice from "./mainSlice";


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, mainSlice)


export const store = configureStore({
    reducer: {main: persistedReducer},
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the home itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: Com