import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {ItemInterface} from "../../store/Interfaces";
import {CategoryInterface} from "../../store/mainSlice";
import { FloatButton, Select, Space, Typography} from "antd";
import { Image } from 'antd';
import './NextUI.css'
import {
    ArrowUpOutlined,
    CodeSandboxOutlined, DropboxOutlined, ZoomInOutlined,
    ZoomOutOutlined
} from "@ant-design/icons";
import Search from "antd/es/input/Search";

const NextUi = () => {

    const mainItems = useSelector((state: any)=> state.main.items)
    const categories = useSelector((state: any)=> state.main.categories)
    const [gridView, setGridView] = useState<boolean>(true)

    let x = window.innerWidth - 20
    if(x > window.innerHeight){
        x = window.innerHeight - 10
    }

    const [categoryList, setCategoryList] = useState<CategoryInterface[]>([])
    const [searchName, setSearchName] = useState<string>("")

    return (
        <>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                <Select mode={"multiple"} allowClear options={categories.map((category: CategoryInterface) => {
                    return {
                        value: category.name,
                        label: category.name
                    }
                })} placeholder="Категории" onChange={(event: string[])=>{
                    setCategoryList(event.map(x=>categories.find((y: CategoryInterface)=>y.name === x)!))
                }} style={{ width: 180, marginTop: 10 }} />
                <Search placeholder="Поиск" style={{width: 160, marginTop: 10}} onChange={event => {
                    setSearchName(event.target.value)
                }} onSearch={(value) => {
                    setSearchName(value)
                }}/>
            </div>
            <FloatButton icon={<ArrowUpOutlined />} type={"primary"} onClick={()=>{
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}></FloatButton>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
                {categories.filter((cat: CategoryInterface)=>{
                    if (categoryList.length === 0){
                        return true
                    }
                    return categoryList.some(list=> list.name.toLowerCase() === cat.name.toLowerCase())
                }).map((category: CategoryInterface)=>{
                    return (
                        <>
                            <Typography.Title style={{background: "rgba(12,12,12,0.46)", color: "white", padding: 10, borderRadius: 5}} level={5}>{category.name}</Typography.Title>
                            {
                                gridView ?
                                    <div  style={{width: "100%", display: "grid", maxWidth: x, gridTemplateColumns: "1fr 1fr 1fr"}}>
                                        {mainItems.filter((item: ItemInterface) => {
                                           if (searchName !== ""){
                                               return item.category === category._id && item.name.toLowerCase().includes(searchName.toLowerCase())
                                           }
                                           return item.category === category._id
                                        }
                                        ).map((item: ItemInterface)=> {
                                            return <div style={{margin: 3, position: "relative", }}>
                                                <Image
                                                    style={{borderRadius: 10}}
                                                       className={"nui-div-hov"}
                                                       width={x / 3 - 6}
                                                       src={item.imageSrc}
                                                       alt={item.name}
                                                       preview={{
                                                           toolbarRender: (
                                                               _,
                                                               {
                                                                   transform: { scale },
                                                                   actions: {onZoomOut, onZoomIn },
                                                               },
                                                           ) => (
                                                               <Space align={"center"}  style={{background: "rgba(0,0,0,0.27)", padding: 10}} size={12} direction={"vertical"} className="toolbar-wrapper">
                                                                   <Space direction={"horizontal"}>
                                                                       <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                       <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                   </Space>
                                                                   <Typography style={{color: "white"}}>{item.name}</Typography>
                                                                   <Typography style={{color: "white"}}>{item.lowPrice + "₸"}/{item.price + "₸"}</Typography>
                                                                   {item.quantity === 0 ?
                                                                       <Typography style={{color: "red"}}>Товар возможно нет в наличии, уточните у поставщика</Typography>
                                                                       :
                                                                       <Space direction={"horizontal"}>

                                                                           {
                                                                               item.inBox && <>
                                                                                   <DropboxOutlined />
                                                                                   {item.inBox}
                                                                               </>

                                                                           }
                                                                           {
                                                                               item.inBlock && <>
                                                                                   <CodeSandboxOutlined />
                                                                                   {item.inBlock}
                                                                               </>
                                                                           }
                                                                       </Space>
                                                                   }
                                                               </Space>
                                                           ),
                                                       }}
                                                ></Image>
                                                <div style={{position: "absolute", bottom: 0, padding: 5, left: 5, background: "rgba(255,255,255,0.68)"}}>
                                                    <Typography style={{color: item.quantity === 0 ? "red" : "black", fontSize: 10}}>{item.lowPrice + "₸ / "}{item.price + "₸"}</Typography>
                                                </div>
                                                <div style={{position: "absolute", padding: 5,fontSize: 10, bottom: 0, right: 0, background: "rgba(255,255,255,0.68)"}}>
                                                    {
                                                        item.inBox && <>
                                                            <DropboxOutlined />
                                                            {" " + item.inBox}
                                                        </>

                                                    }
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    :
                                    <div style={{
                                        width: "100%",
                                        display: "grid",
                                        maxWidth: x,
                                        gridTemplateColumns: "1fr"
                                    }}>
                                        {mainItems.filter((item: ItemInterface) => item.category === category._id).map((item: ItemInterface) => {
                                            return <div style={{margin: 3}}>
                                                <img onClick={() => {
                                                    console.log("click image")
                                                }} className={"nui-div-hov"} width={x - 6} src={item.imageSrc}
                                                     alt={"ss"}></img>
                                                <div style={{background: "rgba(255,255,255,0.73)", textAlign: "center", border: 5}}>
                                                    {item.name}
                                                    <br/>
                                                    Цена {item.price} Оптом {item.lowPrice}
                                                    <br/>
                                                    В блоке {item.inBlock} В коробке {item.inBox}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                            }
                        </>
                    )
                })
                }
        </div>
        </>
    );
};

export default NextUi;