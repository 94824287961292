import React, {Dispatch, SetStateAction, useState} from 'react';
import {Button, Checkbox, Input, InputNumber, Switch} from "antd";
import {ArrowLeftOutlined, CloseOutlined} from "@ant-design/icons";
import {ItemInterface, SellItemInterface} from "../../store/Interfaces";
import API from "../../API/API";
import {useDispatch, useSelector} from "react-redux";
import {changeCartItemPrice, setItem} from "../../store/mainSlice";

const CashPriceChange = (props: {cancelPrice: ()=>void, setChangePrice: Dispatch<SetStateAction<number>>, changePrice: number, origItem: SellItemInterface}) => {
    const {cancelPrice, changePrice, setChangePrice, origItem} = props
    const [globally, setGlobally] = useState<boolean>(false)

    const needItem: ItemInterface = useSelector((state: any) => state.main.items.find((itm: any) => itm.id === origItem.id))
    const [item, setMainItem] = useState<any>(() => {
        if(!needItem) return false
        return Object.assign({inFastCash: needItem.inFastCash}, origItem)
    })
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)

    const onChange = (e: any, param: any) => {
        const newItem = Object.assign({}, item)
        if (Object.hasOwn(newItem, param)) {
            // @ts-ignore
            newItem[param] = e
        }
        setMainItem(newItem)
    }

    const numsRender = <div style={{marginTop: 10}}>
        <InputNumber style={{width: "100%", marginBottom: 20}} value={changePrice}
                     placeholder={"Поставьте цену"} onChange={e => {
            setChangePrice(e ? e : changePrice)
        }}></InputNumber>

        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between"
        }}
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}
            >
                <Button onClick={() => {
                    setChangePrice(current => Number(current + "7"))
                }}
                        style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    7
                </Button>
                <Button
                    onClick={() => {
                        setChangePrice(current => Number(current + "8"))
                    }}
                    style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    8
                </Button>
                <Button
                    onClick={() => {
                        setChangePrice(current => Number(current + "9"))
                    }}
                    style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    9
                </Button>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}
            >

                <Button
                    onClick={() => {
                        setChangePrice(current => Number(current + "4"))
                    }}
                    style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    4
                </Button>
                <Button
                    onClick={() => {
                        setChangePrice(current => Number(current + "5"))
                    }}
                    style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    5
                </Button>
                <Button
                    onClick={() => {
                        setChangePrice(current => Number(current + "6"))
                    }}
                    style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    6
                </Button>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}
            >

                <Button
                    onClick={() => {
                        setChangePrice(current => Number(current + "1"))
                    }}
                    style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    1
                </Button>
                <Button onClick={() => {
                    setChangePrice(current => Number(current + "2"))
                }}
                        style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    2
                </Button>
                <Button onClick={() => {
                    setChangePrice(current => Number(current + "3"))
                }}
                        style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    3
                </Button>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}
            >
                <Button danger type={"primary"} onClick={() => {
                    setChangePrice(0)
                }} style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    <CloseOutlined/>
                </Button>
                <Button onClick={() => {
                    setChangePrice(current => Number(current + "0"))
                }} style={{margin: 5, height: 60, width: 120}} size={"large"}>
                    0
                </Button>
                <Button type={"primary"} onClick={() => {
                    setChangePrice(current => Number(Math.floor(current / 10)))
                }} style={{margin: 5, height: 60, width: 60}} size={"large"}>
                    <ArrowLeftOutlined/>
                </Button>
            </div>
        </div>

    </div>

    console.log("ssa: ", needItem)
    return (
        needItem ?
            <div>
                Сохранить по всей базе <Switch value={globally} onChange={() => setGlobally(g => !g)}></Switch>
                {
                    (!globally ?
                            numsRender
                            :
                            <div>
                                Имя <Input value={item.name} onChange={(e) => {
                                onChange(e.target.value, "name")
                            }}></Input> <br/><br/>
                                В коробке <InputNumber onChange={(e) => {
                                onChange(e, "inBox")
                            }} value={item.inBox}></InputNumber><br/><br/>
                                В блоке <InputNumber onChange={(e) => {
                                onChange(e, "inBlock")
                            }} value={item.inBlock}></InputNumber><br/><br/>
                                Супер Цена <InputNumber onChange={(e) => {
                                onChange(e, "masterPrice")
                            }} value={item.masterPrice}></InputNumber><br/><br/>
                                Оптовая Цена <InputNumber onChange={(e) => {
                                onChange(e, "lowPrice")
                            }} value={item.lowPrice}></InputNumber><br/><br/>
                                Розничная Цена <InputNumber onChange={(e) => {
                                onChange(e, "highPrice")
                            }} value={item.highPrice}></InputNumber><br/><br/>
                                Быстрый Товар <Checkbox checked={item.inFastCash} onChange={e => {
                                onChange(e.target.checked, "inFastCash")
                            }}/><br/><br/>
                                <Button loading={loading} type={"primary"} onClick={() => {
                                    if (needItem) {
                                        setLoading(true)
                                        API.changeItems([{
                                            id: item.id,
                                            description: needItem.description,
                                            barcode: needItem.barcode,
                                            imageSrc: needItem.imageSrc,
                                            price: needItem.price,
                                            lowPrice: item.lowPrice,
                                            inBlock: item.inBlock ? item.inBlock : 0,
                                            inBox: item.inBox ? item.inBox : 0,
                                            highPrice: item.highPrice,
                                            masterPrice: item.masterPrice,
                                            name: item.name,
                                            quantity: needItem.quantity,
                                            category: needItem.category,
                                            inFastCash: item.inFastCash
                                        }]).then(() => {
                                            dispatch(setItem({
                                                id: item.id, item: {
                                                    id: item.id,
                                                    description: needItem.description,
                                                    barcode: needItem.barcode,
                                                    imageSrc: needItem.imageSrc,
                                                    price: needItem.price,
                                                    lowPrice: item.lowPrice,
                                                    inBlock: item.inBlock ? item.inBlock : 0,
                                                    inBox: item.inBox ? item.inBox : 0,
                                                    highPrice: item.highPrice,
                                                    masterPrice: item.masterPrice,
                                                    name: item.name,
                                                    quantity: needItem.quantity,
                                                    category: needItem.category,
                                                    inFastCash: needItem.inFastCash
                                                }
                                            }))
                                            dispatch(changeCartItemPrice({
                                                id: item.id,
                                                low: item.lowPrice,
                                                high: item.highPrice,
                                                master: item.masterPrice,
                                                name: item.name
                                            }))

                                        }).catch(error => {
                                            console.log(error)
                                        }).finally(() => {
                                            setLoading(false)
                                            cancelPrice()
                                        })
                                    }
                                }}>Сохранить</Button>

                            </div>
                    )
                }
            </div>
            : numsRender

    );
};

export default CashPriceChange;