import React, {useRef, useState} from 'react';
import {Button, Card, FloatButton, Input, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {ArrowLeftOutlined} from "@ant-design/icons";
import API from "../../../API/API";
import {useSelector} from "react-redux";
import {ItemInterface} from "../../../store/Interfaces";

const AddBarcodeMap = () => {

    const items: ItemInterface[] = useSelector((state: any) => state.main.items)

    const navigate = useNavigate()
    const [val, setVal] = useState<string[]>([])
    const [mainVal, setMainVal] = useState<string>("")
    const [bufVal, setBufVal] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [foundItem, setFoundItem] = useState<ItemInterface | undefined>(undefined)
    return (
        <div>


            <Space direction={"vertical"} style={{justifyContent: "center", alignItems: "center", width: "100%", marginTop: 100}}>
                <Space direction={"horizontal"}>
                    <Input value={mainVal} onKeyPress={(e=>{
                        if(e.key === "Enter"){
                            let finder = false
                            items.forEach(item=>{
                                if(val[0] === item.barcode){
                                    setFoundItem(item)
                                    finder = true
                                }
                            })
                            if(!finder) {
                                setFoundItem(undefined)
                            }
                        }
                })} onChange={(e)=>{
                        setMainVal(e.target.value)
                        const newVal: string[] = Object.assign([], val)
                        newVal[0] = e.target.value
                        setVal(newVal)
                    }} style={{minWidth: 250}} placeholder={"Главный"}></Input>
                    <Button onClick={()=>{
                        let finder = false
                        items.forEach(item=>{
                            if(val[0] === item.barcode){
                                setFoundItem(item)
                                finder = true
                            }
                        })
                        if(!finder) {
                            setFoundItem(undefined)
                        }
                    }}>
                        Ок
                    </Button>
                </Space>

                <Space>
                <Input
                    placeholder={"Добавочное"}
                    value={bufVal}
                    onChange={(e)=>{
                        // console.log("e:", e)
                        setBufVal(e.target.value)
                    }}
                    onKeyPress={e=>{
                        if(e.key === "Enter") {
                            if(val.includes(bufVal) || bufVal === "") {
                                setBufVal("")
                                return
                            }
                            const cop = Object.assign([], val)
                            cop.push(bufVal)
                            setVal(cop)
                            setBufVal("")
                        }
                    }}>

                </Input>
                    <Button danger onClick={()=>{
                        setVal([])
                        setBufVal("")
                        setMainVal("")
                    }}>
                        Очистить
                    </Button>
                </Space>
                {val.map(((vv, index)=>{
                    return <div style={{display: "flex", flexDirection: "row"}}>
                        <span>{vv}</span>
                        <button onClick={()=>{
                            const copyy = Object.assign([], val)
                            copyy.splice(index, 1)
                            setVal(copyy)
                        }}></button>
                    </div>
                }))}
                <Button loading={loading} disabled={loading} onClick={()=>{
                    if(!foundItem) return
                    setLoading(true)
                    API.addBarcodeMap(val[0], val)
                        .then(data=>{
                            console.log(data)
                        })
                        .catch(error=>{
                            console.log("er: ", error)
                        })
                        .finally(()=>{
                            setLoading(false)
                            setVal([])
                            setBufVal("")
                            setMainVal("")
                        })
                }}>
                    Добавить
                </Button>
                {foundItem && <Card>
                    <div>Имя: {foundItem.name}</div>
                    <div>Супер Цена: {foundItem.masterPrice}</div>
                    <div>Оптовая: {foundItem.lowPrice}</div>
                    <div>Розничная: {foundItem.highPrice}</div>
                </Card>}
            </Space>
            <FloatButton type={"primary"}
                         icon={<ArrowLeftOutlined />} onClick={()=>{
                navigate("/admin")
            }}></FloatButton>
        </div>
    );
};

export default AddBarcodeMap;