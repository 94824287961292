import React, { useContext, useEffect, useRef, useState } from 'react';
import {Checkbox, ConfigProvider, GetRef, InputRef} from 'antd';
import { Button, Form, Input, Table } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {setCategories, setItem, setItems} from "../../../store/mainSlice";
import {ItemInterface} from "../../../store/Interfaces";
import API from "../../../API/API";
import {ReloadOutlined} from "@ant-design/icons";

type FormInstance<T> = GetRef<typeof Form<T>>;
export function getRandomIntInclusive(min: number, max: number) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
}

export const codePutter = (dataSource: ItemInterface[], newBarcoders: ItemInterface[]):string =>{
    let newB = "sh-" + getRandomIntInclusive(100000, 999999)
    let index = dataSource.findIndex(oneData => oneData.barcode === newB)
    if(index === -1) {
        index = newBarcoders.findIndex(oneData => oneData.barcode === newB)
    }
    if(index === -1){
        return newB
    }else
        return codePutter(dataSource, newBarcoders)
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const deepEqual : any = (x:any, y: any) => {
    return (x && y && typeof x === 'object' && typeof y === 'object') ?
        (Object.keys(x).length === Object.keys(y).length) &&
        Object.keys(x).reduce(function(isEqual, key) {
            return isEqual && deepEqual(x[key], y[key]);
        }, true) : (x === y);
}

interface Item {
    id: string;
    name: string;
    quantity: number;
    inBox: number;
    inBlock: number;
    price: number;
    masterPrice: number;
    lowPrice: number;
    highPrice: number;
    barcode: string
    inFastCash?: boolean
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr className={"chgchg"} {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                       title,
                                                       editable,
                                                       children,
                                                       dataIndex,
                                                       record,
                                                       handleSave,
                                                       ...restProps
                                                   }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            console.log("trying: ", values)
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children, width;
    // @ts-ignore
    if(restProps.style){
        // @ts-ignore
        width = restProps.style.width
    }
    // @ts-ignore
    let s = children[1]
    if (editable) {
        if(title !== "Быстрый") {
            childNode = editing ? (
                <Form.Item
                    style={{margin: 0, width: width}}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
                </Form.Item>
            ) : (
                <div style={{width: "100%", textAlign: "center"}} onClick={toggleEdit}>
                    {s ? children : 0}
                    {/*{children}*/}
                </div>
            );
        }
        else {
            childNode = <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><Checkbox onClick={e=>{
                console.log("ee: ", e)
                const newRecord = Object.assign({}, record)
                newRecord.inFastCash = !newRecord.inFastCash
                handleSave(newRecord)
            }} checked={record.inFastCash}></Checkbox>
            </div>
        }
        // console.log("children: ", children)
    }
    // console.log("sss: ", restProps)

    return <td className={"sds"} style={{overflowX: "auto", width: width, height: 80}} {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    id: string;
    name: string;
    quantity: number;
    inBox: number;
    inBlock: number;
    price: number;
    masterPrice: number;
    lowPrice: number;
    highPrice: number;
    barcode: string,
    description: string,
    category: string;
    imageSrc: string;
    inFastCash: boolean
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
        title: 'Наименование',
        dataIndex: 'name',
        editable: true,
        width: "25%",
    },
    {
        title: 'Количество',
        dataIndex: 'quantity',
        editable: true,
        width: "7%",
    },
    {
        title: 'В коробке',
        dataIndex: 'inBox',
        editable: true,
        width: "7%",

    },
    {
        title: 'В блоке',
        dataIndex: 'inBlock',
        editable: true,
        width: "7%",

    },
    {
        title: 'Стоимость',
        dataIndex: 'price',
        editable: true,
        width: "8%",
    },
    {
        title: 'Мастер',
        dataIndex: 'masterPrice',
        editable: true,
        width: "8%",
    },
    {
        title: 'Оптовая',
        dataIndex: 'lowPrice',
        editable: true,
        width: "8%",
    },
    {
        title: 'Розничная',
        dataIndex: 'highPrice',
        editable: true,
        width: "8%",

    },
    {
        title: 'Штрих код',
        dataIndex: 'barcode',
        editable: true,
        width: "15%",
    },
    {
        title: 'Быстрый',
        dataIndex: 'inFastCash',
        editable: true,
        width: "7%",
    },
];

const ItemsTable: React.FC = () => {
    const dataSource: ItemInterface[] = useSelector((state: any) => state.main.items);
    const dispatch = useDispatch();

    const [changedItems, setChangedItems] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    const handleSave = (row: DataType) => {
        console.log("handling: ", row)
        const index = dataSource.findIndex((item: ItemInterface) => row.id === item.id);
        const item = dataSource[index];
        if (deepEqual(item, row)){
            console.log("no change")
        }else {
            console.log("change")
            dispatch(setItem({id: row.id, item: row}));
            if(!changedItems.includes(row.id)){
                const newChanged = Object.assign([], changedItems);
                newChanged.push(item.id)
                setChangedItems(newChanged)
            }
        }
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <div>
            <Button loading={loading} disabled={loading} onClick={()=>{
                const savingItems: ItemInterface[] = []
                changedItems.forEach(itemID=>{
                    savingItems.push(dataSource.find((item:ItemInterface) => item.id === itemID)!)
                })
                setLoading(true)
                API.changeItems(savingItems)
                    .then(data=>{
                        console.log(data)
                        return API.getAdminicItems()
                    })
                    .then(data=>{
                        console.log("new data: ", data.data)
                        dispatch(setItem(data.data))
                    })
                    .catch(error=>{
                        console.log("err: ", error)
                    }).finally(()=>{
                        setLoading(false)
                        setChangedItems([])
                    })
            }} type="primary" style={{ marginBottom: 16 }}>
                Сохранить в базу данных
            </Button>
            <Button loading={loading} disabled={loading} onClick={()=>{
                setLoading(true)
                API.loadCategories()
                    .then((data)=>{
                        // console.log(data.data)
                        if(data.data.length === 0) return
                        dispatch(setCategories(data.data))
                        return API.getAdminicItems()
                    })
                    .then(itemsData=>{
                        if(itemsData === undefined) return
                        dispatch(setItems(itemsData.data))
                    })
                    .catch(reason => {
                        console.log("reason: ", reason)
                    })
                    .finally(()=>{
                        setLoading(false)
                    })

            }} style={{marginTop: 10}}><ReloadOutlined/></Button>
            <Button loading={loading} disabled={loading} onClick={()=>{
                // setLoading(true)
                const newBarcoders: ItemInterface[] = []
                dataSource.forEach(datum =>{
                    if(datum.barcode === undefined || datum.barcode.length < 5){

                        newBarcoders.push({...datum, barcode: codePutter(dataSource, newBarcoders)})
                    }
                })
                console.log(newBarcoders)
                setLoading(true)
                API.changeItems(newBarcoders)
                    .then(data=>{
                        console.log(data)
                        return API.getAdminicItems()
                    })
                    .then(data=>{
                        console.log("new data: ", data.data)
                        dispatch(setItem(data.data))
                    })
                    .catch(error=>{
                        console.log("err: ", error)
                    }).finally(()=>{
                    setLoading(false)
                    setChangedItems([])
                })

            }}>Barcoder</Button>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            /* here is your component tokens */
                            // cellPaddingBlock: 0,
                            cellPaddingInline: 0
                        },
                    },
                }}
            >
                <Table
                    virtual
                    scroll={{x: window.innerWidth - 20, y: window.innerHeight - 200}}
                    pagination={false}
                    components={components}
                    // rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    // dataSource={dataSource.filter(item => item.inFastCash)}
                    columns={columns as ColumnTypes}
                />
            </ConfigProvider>

        </div>
    );
};

export default ItemsTable;