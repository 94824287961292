import React, {useState} from 'react';
import {Button, Input, Select} from "antd";

const FastAddItem = () => {

    const [itemData, setItemData] = useState([{
        name: "",
        price: 0,
        masterPrice: 0,
        lowPrice: 0,
        highPrice: 0,
        barcode: ""
    }])


    return (
        <div style={{margin: 20}}>

            {itemData.map((item, index)=>{
                return(
                    <>
                        <div style={{display: "flex", flexDirection: "row", marginTop: 10}}>
                            <Input placeholder={"Имя"} value={item.name}></Input>
                            <Input placeholder={"Цена"} value={item.price}></Input>
                            <Input placeholder={"суперцена"} value={item.masterPrice}></Input>
                            <Input placeholder={"оптом"} value={item.lowPrice}></Input>
                            <Input placeholder={"розница"} value={item.highPrice}></Input>
                            <Input placeholder={"штрих"} value={item.barcode}></Input>
                            <Select></Select>
                        </div>
                        {index === itemData.length - 1 &&
                            <Button onClick={()=>{
                                const ii = Object.assign([], itemData)
                                ii.push({
                                    name: "",
                                    price: 0,
                                    masterPrice: 0,
                                    lowPrice: 0,
                                    highPrice: 0,
                                    barcode: ""
                                })
                                setItemData(ii)
                            }} type={"primary"}>+</Button>
                        }
                    </>
                )
            })}
        </div>
    );
};

export default FastAddItem;