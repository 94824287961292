import React, {useState} from 'react';
import NextUIPriced from "./NextUIPriced";
const PricedImages = () => {

    const [catShow, setCatShow] = useState<boolean>(true)

    return (
        <div className={"pattern-container"}>
            {/*<div className={"pattern"}/>*/}
            {/*<div className={"pattern-upper"}/>*/}
            {/*<div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row"}}>*/}
            {/*    <Typography.Title level={4} style={{padding: 5, background: "rgba(255,255,255,0.8)", color: "black", borderRadius: 5}}>г. Туркестан, Кентауская трасса 42</Typography.Title>*/}
            {/*    <img onClick={()=>{setCatShow(show=> !show)}} width={200} alt={"sd"} src={logo}/>*/}
            {/*    <Typography.Title style={{padding: 5, background: "rgba(255,255,255,0.8)", color: "black", borderRadius: 5}} level={4}>Телефон +7 (777) 468-30-60</Typography.Title>*/}
            {/*</div>*/}
            <div onDoubleClick={()=>{
                setCatShow(show=> !show)
            }} style={{marginTop: 0}}>
                <NextUIPriced catShow={catShow}/>
            </div>
            <div style={{height: 100}}></div>

        </div>
    );
};

export default PricedImages;