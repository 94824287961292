import React from 'react';
import Navigator from "../Navigator/Navigator"
import CartItems from "./CartItems";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import {Button, Checkbox, FloatButton, Input, Modal, notification, Select, Spin, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import TextArea from "antd/es/input/TextArea";
import API from '../../API/API';
import {clearCart} from "../../store/mainSlice";
import {useNavigate} from "react-router-dom";

const CartPage = () => {

    const cost = useSelector((state: any)=>{
        let sum = 0
        state.main.cart.items.forEach((item: any)=>{
            sum += item.quantity * item.price
        })
        return sum
    })

    const address = useSelector((state: any)=>state.main.user.address)
    const cartItems:any[] = useSelector((state: any)=>state.main.cart.items)

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
        setDeliveryAddress(value)
    };

    const [selfDelivery, setSelfDelivery] = React.useState<boolean>(false)
    const [modalOpen, setModalOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [comments, setComments] = React.useState<string>("")
    const [deliveryAddress, setDeliveryAddress] = React.useState<string>(address[0])
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <div style={{width: "100%", height: "calc(100vh + 220px)"}}>
            <div className={"pattern"}/>
            {
                loading &&
                <Spin style={{top: 300, position: "absolute", width: "100%", height: "100%"}} tip="Загрузка" size="large"/>
            }
            {contextHolder}
            <Navigator/>
            <CartItems/>
            {/*<div style={{height: 250}}>*/}

            {/*</div>*/}

            <FloatButton
                style={{ bottom: 150}}
                shape={"circle"}
                onClick={()=>{
                    navigate("/store")
                }}
                type={"default"}
                icon={<ArrowLeftOutlined />}
            >
            </FloatButton>

            <FloatButton
                style={{width: 200, bottom: 100}}
                shape={"square"}
                description={"Сумма: " + cost + "₸"}
                onClick={()=>{
                }}
                type={"default"}
                // icon={<ArrowRightOutlined />}
            >
            </FloatButton>

            <FloatButton
                style={{width: 200}}
                shape={"square"}
                description={"Оформить заказ"}
                onClick={()=>{setModalOpen(true)}}
                type={"primary"}
                // icon={<ArrowRightOutlined />}
            >
            </FloatButton>

            <Modal
                open={modalOpen}
                title={"Подтвердите заказ"}
                onCancel={()=>{
                    setModalOpen(false)
                }}
                footer={
                    <>
                        <Button onClick={()=>(setModalOpen(false))}>Отмена</Button>
                        <Button onClick={()=>{
                            setModalOpen(false)
                            API.makeOrder({
                                address: selfDelivery ? "self_delivery" : deliveryAddress,
                                items: cartItems.map((item)=>{
                                    return {name: item.name, quantity: item.quantity, price: item.price}
                                }),
                                comments: comments,
                                cost,
                            }).then((data)=>{
                                api.success({
                                    message: `Заказ оформлен`,
                                    description:
                                        'Ваш заказ на сумму ' + cost + '₸ успешно оформлен',
                                    placement: "top",
                                });
                                setLoading(true)
                            }).then(()=>{
                                dispatch(clearCart())
                                setTimeout(()=>{
                                    navigate("/store")
                                }, 2000)
                            }).catch(error=>{
                                api.error({
                                    message: `Заказ не был оформлен`,
                                    description:
                                        'Ваш заказ на сумму ' + cost + '₸ не был оформлен. Пожалуйста обратитесь к администратору',
                                    placement: "top",
                                });
                                console.log("error: ", error)
                            })
                        }} type={"primary"}>Оформить</Button>
                    </>
                }
            >
                <Typography>
                    Сумма: {cost}
                </Typography>

                <Typography>
                    {"Отправить товар на   "}

                    <Select
                        disabled={selfDelivery}
                        defaultValue={address[0]}
                        placeholder={"адрес"}
                        onChange={handleChange}
                        options={address.map((add: string)=>{return{value: add, label: add}})}
                    />
                </Typography>
                <Typography>Самовывоз {" "}
                    <Checkbox value={selfDelivery}
                              onChange={e=>{
                                  console.log(e.target.checked)
                                  setSelfDelivery(e.target.checked)
                              }}
                    />
                </Typography>
                <TextArea onChange={event => setComments(event.target.value)} value={comments} placeholder={"Комментарии"}/>
            </Modal>

        </div>
    );
};

export default CartPage;