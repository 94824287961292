import React, {MutableRefObject, RefObject, useState} from 'react';
import {Button, Input, Typography} from "antd";
import Icon, {ArrowLeftOutlined, CloseOutlined, CodeSandboxOutlined, DropboxOutlined} from "@ant-design/icons";
import {Simulate} from "react-dom/test-utils";

const CashItemQuantityModal = (props: {item: any, cashItemQuantity: number, changeQuantity: MutableRefObject<Number>}) => {
    const [quantity, setQuantity] = useState(props.cashItemQuantity);

    props.changeQuantity.current = quantity
    console.log(props.item)


    return (
        <>
            <Input value={quantity}/>
            {/*<Input value={cashItems[currentItem.current].quantity}/>*/}
            <div style={{display: "flex", justifyContent: "space-evenly", flexDirection: "row", alignItems: "center", marginTop: 10}}>

                {/*<Button style={{height: 60, width: 60}} disabled={quantity === 0} type={"primary"} onClick={() => {setQuantity(q => q - 1 )}} size={"large"}>*/}
                {/*    -*/}
                {/*</Button>*/}

            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between"
            }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
                >
                    <Button onClick={() => {
                        setQuantity(current => Number(current + "7"))
                    }}
                            style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        7
                    </Button>
                    <Button
                        onClick={() => {
                            setQuantity(current => Number(current + "8"))
                        }}
                        style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        8
                    </Button>
                    <Button
                        onClick={() => {
                            setQuantity(current => Number(current + "9"))
                        }}
                        style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        9
                    </Button>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
                >

                    <Button
                        onClick={() => {
                            setQuantity(current => Number(current + "4"))
                        }}
                        style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        4
                    </Button>
                    <Button
                        onClick={() => {
                            setQuantity(current => Number(current + "5"))
                        }}
                        style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        5
                    </Button>
                    <Button
                        onClick={() => {
                            setQuantity(current => Number(current + "6"))
                        }}
                        style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        6
                    </Button>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
                >

                    <Button
                        onClick={() => {
                            setQuantity(current => Number(current + "1"))
                        }}
                        style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        1
                    </Button>
                    <Button onClick={() => {
                        setQuantity(current => Number(current + "2"))
                    }}
                            style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        2
                    </Button>
                    <Button onClick={() => {
                        setQuantity(current => Number(current + "3"))
                    }}
                            style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        3
                    </Button>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
                >
                    <Button danger type={"primary"} onClick={() => {
                        setQuantity(0)
                    }} style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        <CloseOutlined/>
                    </Button>
                    <Button onClick={() => {
                        setQuantity(current => Number(current + "0"))
                    }} style={{margin: 5, height: 60, width: 120}} size={"large"}>
                        0
                    </Button>
                    <Button type={"primary"} onClick={() => {
                        setQuantity(current => Number(Math.floor(current / 10)))
                    }} style={{margin: 5, height: 60, width: 60}} size={"large"}>
                        <ArrowLeftOutlined/>
                    </Button>
                </div>

                {props.item.inBlock && <div style={{display: "flex", flexDirection: "row"}}><CodeSandboxOutlined /><Typography.Text>{props.item.inBlock}</Typography.Text></div>}
                {props.item.inBox && <div style={{display: "flex", flexDirection: "row"}}> <DropboxOutlined /><Typography.Text>{props.item.inBox}</Typography.Text></div>}
            </div>

                {/*<Button style={{ height: 60, width: 60}} type={"primary"} onClick={()=> {setQuantity(q => q + 1)}} size={"large"}>*/}
                {/*    +*/}
                {/*</Button>*/}

            </div>

        </>
    );
};

export default CashItemQuantityModal;