import React, {useState} from 'react';
import {CheckCircleTwoTone, FundProjectionScreenOutlined, HistoryOutlined, LoadingOutlined} from "@ant-design/icons";
import {Button, Card, Popconfirm, Typography} from "antd";
import API from "../../API/API";
import {OrderInterface} from "../../store/mainSlice";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const OrderCard = (props: {type: "admin" | "user", order: OrderInterface, index: number, updateOrders: CallableFunction}) => {
    const order = props.order
    const index = props.index
    const date = new Date(order.date)
    const navigate = useNavigate()

    const user = useSelector((state: any)=>state.main.user)
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    return (
        <Card
            // key={index}
            style={{width: "90%", margin: "10px", position: "relative"}}
            title={"Заказ: " + date.toLocaleDateString() + " " + date.toLocaleTimeString()}
            extra={
                order.status === "done" ?
                    <CheckCircleTwoTone twoToneColor="#52c41a"/>
                    :
                    order.status === "working" ? <FundProjectionScreenOutlined /> : <HistoryOutlined />
            }
            actions={props.type === "user" ? [<Button type={"primary"} onClick={()=>{
                navigate("/myorders/invoice/" + index)
            }}>
                Посмотреть чек
            </Button>
            ] : [
                <Button type={"primary"} onClick={()=>{
                    navigate("/myorders/invoice/" + index)
                }}>
                    Посмотреть чек
                </Button>,
                <Popconfirm
                    title="Подтверждаете?"
                    description="Вы действительно хотите выполнить этот заказ?"
                    open={open}
                    onConfirm={()=>{
                        setConfirmLoading(true);
                        let newStatus = "working"
                        if(order.status === "working"){
                            newStatus = "done"
                        }
                        API.setOrderStatus(order.id, newStatus, user.name).then((data)=>{
                            console.log(data)
                            setOpen(false);
                            setConfirmLoading(false);
                            props.updateOrders()
                        }).catch((reason)=>{
                            console.log("reason: ", reason)
                        })
                    }}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={()=>{
                        setOpen(false)
                    }}
                >
                    <Button type="primary" onClick={()=>{setOpen(true)}}>
                        {order.status === "pending" ? "Выполнить?" : "Далее"}
                    </Button>
                </Popconfirm>
            ]}
        >
            <Typography>
                {props.type ==="user" ? (order.supervisor && order.supervisor !== "") ? "Менеджер: " + order.supervisor : "Не определен" :
                                            "Заказчик: " + order.ordererName
                }
            </Typography>
            <Typography>
                Сумма: {order.cost}
            </Typography>
            <Typography>
                Адрес: {order.deliveryAddress === "self_delivery" ? "Самовывоз" : order.deliveryAddress}
            </Typography>
            <Typography>
                Статус заказа: {order.status === "pending" && "Не просмотрен"} {order.status === "working" && "В процессе"} {order.status === "done" && "Выполнен"}
            </Typography>
            {
                order.comments !== "" &&
                <Typography>Комментарии: {order.comments}</Typography>
            }
        </Card>
    );
};

export default OrderCard;