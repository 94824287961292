import React, {useEffect, useRef, useState} from 'react';
import JsBarcode from "jsbarcode";
import {ItemInterface} from "../../../store/Interfaces";
import {useSelector} from "react-redux";
import {Button} from "antd";
//@ts-ignore
import * as saveAsPng from "save-svg-as-png";
import JSZip from "jszip";
import {saveSvgAsPng, svgAsDataUri, svgAsPngUri} from "save-svg-as-png";
import {CategoryInterface} from "../../../store/mainSlice";
import bwipjs from 'bwip-js';

const Barcoder = () => {
    const items: ItemInterface[] = useSelector((state: any) => state.main.items);
    const categories: CategoryInterface[] = useSelector((state: any) => state.main.categories);
    let num = 232
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [ss, seta] = useState<string>("");

    items.forEach(item=>{
        if(item.highPrice === 1127){
            console.log(item)
        }
    })
    useEffect(() => {
        // let svg = bwipjs.toSVG({
        //     bcid:        'code128',       // Barcode type
        //     text:        '0123456789',    // Text to encode
        //     height: 2,           // Bar height, in millimeters
        //     includetext: true,            // Show human-readable text
        //     textxalign:  'center',        // Always good to set this
        //     textcolor:   'ff0000',        // Red text
        // });
        // // @ts-ignore
        // let [ , width, height ] = /viewBox="0 0 (\d+) (\d+)"/.exec(svg);
        // let span = document.getElementById("sss")!
        // span.style.display = 'inline-block';
        // span.style.width = width + 'px';
        // span.style.height = height + 'px';
        // span.innerHTML = svg;
        // console.log("ss: ", svg)
        // JsBarcode("#barcode", "Hftghjjki!", {
        //     displayValue: false,
        //     marginTop: 30,
        //     height: 30
        // });


    }, []);
    return (
        <div>
            <Button onClick={ async ()=>{


                const zip = new JSZip();
                const folders: any = {}
                categories.forEach((category:CategoryInterface) => {
                    const folder = zip.folder(category.name)
                    folders[category._id] = folder
                })

                const promises = []
                let x = 0
                for await (const item of items) {
                    if(item.name !== "Цветная Бумага A4-CSZ-148") {
                        continue
                    }
                    const svg = document.createElementNS("http://www.w3.org/2000/svg","svg")
                    const svgContainer = document.createElementNS("http://www.w3.org/2000/svg","svg")

                    const textUp = document.createElementNS("http://www.w3.org/2000/svg","text")
                    textUp.innerHTML = item.name
                    textUp.setAttribute( "y", "20px")
                    textUp.setAttribute( "x", "10px")

                    const textDown = document.createElementNS("http://www.w3.org/2000/svg","text")
                    textDown.innerHTML = "Оптом: " + item.lowPrice + "₸ В розницу: " + item.highPrice + "₸"
                    textDown.setAttribute( "y", "80px")
                    textDown.setAttribute( "x", "10px")

                    JsBarcode(svg, item.barcode, {
                        displayValue: false,
                        marginTop: 30,
                        height: 30,
                        width: 1
                    });

                    let ww = svg.width.baseVal.value ? svg.width.baseVal.value : 300;
                    if (item.name.length > 30) {
                        ww = item.name.length * 8.7
                    }
                    console.log(textUp)

                    if (ww < 27*8.7) {
                        ww = 27*8.7
                    }

                    const rect = document.createElementNS("http://www.w3.org/2000/svg","rect")
                    rect.setAttribute("fill", "#ffffff")
                    rect.setAttribute("width", ww+"px")
                    rect.setAttribute("height", "100px")

                    svgContainer.append(rect)
                    svgContainer.append(svg)
                    svgContainer.append(textUp)
                    svgContainer.append(textDown)

                    svgContainer.setAttribute("width", ww+"px")
                    svgContainer.setAttribute("height", "100px")

                    // document.getElementById("appender")!.appendChild(svgContainer)
                    //@ts-ignore
                    promises.push(svgAsPngUri(svgContainer).then((data)=>{
                        return {data, name: item.name, category: item.category}
                    }))
                    x++
                    if (x===3){

                    }
                }

                //@ts-ignore
                Promise.all(promises).then(async (data: any)=>{
                    console.log( data)
                // Promise.all(promises).then(async (data: string[])=>{
                    let count = 0
                    for await (const datum of data){
                        folders[datum.category].file(datum.name + ".png", datum.data.substr(datum.data.indexOf(',')+1), {base64: true});
                        count++
                    }
                }).finally(()=>{
                    zip.generateAsync({type:"blob"})
                        .then(function(content) {
                            // see FileSaver.js
                            const link = document.createElement("a")
                            link.href = URL.createObjectURL(content)
                            link.download = "barcode.zip"
                            link.click()
                            link.remove()
                        });
                })
            }}>ok</Button>
            <svg id={"down"} style={{height: 200}}>
                <svg id={"barcode"}></svg>
                <text y={20} x={155} textAnchor={"middle"}>vgbhj</text>
                <text y={80} x={155} textAnchor={"middle"}>vgbhj</text>
            </svg>
            <span>
                <span id={"sss"}></span>
            </span>

            <div id={"appender"}></div>

        </div>
    );
};

export default Barcoder;