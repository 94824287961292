import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {Button, Input, InputNumber, Typography} from "antd";
import {ArrowLeftOutlined, CloseOutlined} from "@ant-design/icons";

const CashCounterModal = (props: {comments: string, setComments: any, value: number, setCashOk: Dispatch<SetStateAction<boolean>>, money: number, setMoney: Dispatch<SetStateAction<number>>}) => {

    const money = props.money
    const setMoney = props.setMoney

    const height = 60, width = 60, widthMoney = 80;

    useEffect(() => {
        props.setCashOk(props.value > money)
    }, [money, props]);
    return (
        <div style={{display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center"}}>

            <InputNumber style={{width: "100%", marginBottom: 10}} value={money} onChange={(e=>{
                setMoney(e ? e : 0)})}></InputNumber>
            <Input placeholder={"Примечания"} value={props.comments} onChange={e=>{
                props.setComments(e.target.value)
            }}>

            </Input>

            <div style={{
                marginTop: 15,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center"
            }}
            >

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
                >
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                    >
                        <Button onClick={()=>{
                            setMoney(current=> Number(current + "7"))
                        }}
                            style={{margin: 5, height, width}} size={"large"}>
                            7
                        </Button>
                        <Button
                            onClick={()=>{
                                setMoney(current=> Number(current + "8"))
                            }}
                            style={{margin: 5, height, width}} size={"large"}>
                            8
                        </Button>
                        <Button
                            onClick={()=>{
                                setMoney(current=> Number(current + "9"))
                            }}
                            style={{margin: 5, height, width}} size={"large"}>
                            9
                        </Button>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                    >

                        <Button
                            onClick={()=>{
                                setMoney(current=> Number(current + "4"))
                            }}
                            style={{margin: 5, height, width}} size={"large"}>
                            4
                        </Button>
                        <Button
                            onClick={()=>{
                                setMoney(current=> Number(current + "5"))
                            }}
                            style={{margin: 5, height, width}} size={"large"}>
                            5
                        </Button>
                        <Button
                            onClick={()=>{
                                setMoney(current=> Number(current + "6"))
                            }}
                            style={{margin: 5, height, width}} size={"large"}>
                            6
                        </Button>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                    >

                        <Button
                            onClick={()=>{
                                setMoney(current=> Number(current + "1"))
                            }}
                            style={{margin: 5, height, width}} size={"large"}>
                            1
                        </Button>
                        <Button onClick={()=>{
                            setMoney(current=> Number(current + "2"))
                        }}
                            style={{margin: 5, height, width}} size={"large"}>
                            2
                        </Button>
                        <Button onClick={()=>{
                            setMoney(current=> Number(current + "3"))
                        }}
                                style={{margin: 5, height, width}} size={"large"}>
                            3
                        </Button>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                    >
                        <Button danger type={"primary"} onClick={()=>{
                            setMoney(0)
                        }} style={{margin: 5, height, width}} size={"large"}>
                            <CloseOutlined />
                        </Button>
                        <Button onClick={()=>{
                            setMoney(current=> Number(current + "0"))
                        }} style={{margin: 5, height, width}} size={"large"}>
                            0
                        </Button>
                        <Button type={"primary"} onClick={()=>{
                            setMoney(current=> Number(Math.floor(current / 10)))
                        }} style={{margin: 5, height, width}} size={"large"}>
                            <ArrowLeftOutlined />
                        </Button>
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
                >
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center"
                    }}
                    >
                        <Button onClick={()=>{
                            setMoney(current=> Number(current + 10000))
                        }} style={{margin: 10, height, width: widthMoney}}>
                            +10000
                        </Button>
                        <Button onClick={()=>{
                            setMoney(current=> Number(current + 20000))
                        }} style={{margin: 10, height, width: widthMoney}}>
                            +20000
                        </Button>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center"
                    }}
                    >
                        <Button onClick={()=>{
                            setMoney(current=> Number(current + 2000))
                        }} style={{margin: 10, height, width: widthMoney}}>
                            +2000
                        </Button>
                        <Button onClick={()=>{
                            setMoney(current=> Number(current + 5000))
                        }} style={{margin: 10, height, width: widthMoney}}>
                            +5000
                        </Button>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center"
                    }}
                    >

                        <Button onClick={()=>{
                            setMoney(current=> Number(current + 500))
                        }} style={{margin: 10, height, width: widthMoney}}>
                            +500
                        </Button>
                        <Button onClick={()=>{
                            setMoney(current=> Number(current + 1000))
                        }} style={{margin: 10, height, width: widthMoney}}>
                            +1000
                        </Button>
                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                    >

                        <Button type={"primary"} onClick={()=>{
                            setMoney(Number(props.value))
                        }} style={{margin: 5}}>
                            Без Сдачи
                        </Button>
                        <Button type={"primary"} style={{margin: 5}} >
                            Оплата Картой
                        </Button>
                    </div>
                </div>
            </div>
            {props.value < money ? <Typography.Title level={5} style={{marginTop: 10}}>Сдача: {money - props.value}</Typography.Title> : <></>}
        </div>

    );
};

export default CashCounterModal;