import React from 'react';
import {FloatButton, Typography} from "antd";
import "./Invoice.css"
import {useSelector} from "react-redux";
import {ArrowLeftOutlined, ArrowRightOutlined, DownCircleFilled, DownloadOutlined} from "@ant-design/icons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {jsPDF} from "jspdf";
import fontss from "./Roboto-Regular-normal";
import html2canvas from "html2canvas";


const InvoiceComponent = () => {

    const loc:any = useParams()
    const order = useSelector((state: any)=>state.main.user.orders[loc.id])
    const navigate = useNavigate()
    return (
        <div >
        <div id={"content-invoice"} style={{display: "flex", flexDirection: "column", alignItems: "center", overflowY: "auto", width: "100%"}}>

            <div style={{ width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                <Typography.Title level={4}>Шынар Кеңсе</Typography.Title>
                <Typography.Title level={4}>Товарный чек</Typography.Title>
            </div>
            <div style={{ width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                <Typography>Дата: {new Date(order.date).toLocaleDateString()}</Typography>
            </div>
            <div style={{ width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                <Typography>Адрес: {order.deliveryAddress === "self_delivery" ? "Самовывоз" : order.deliveryAddress}</Typography>
            </div>

            <br/>
            <table className="table-invoice">
                <thead className={"i-head"}>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Наименование</th>
                        <th scope="col">Количество</th>
                        <th scope="col">Цена</th>
                        <th scope="col">Общая Сумма</th>
                    </tr>
                </thead>
                <tbody className={"i-tbody"}>
                    {
                        order.cartItems.map((item:any, i:number)=>{
                            return <tr key={i}>
                                <th style={{width: "5%"}} scope={"row"}>{i + 1}</th>
                                <td style={{width: "50%"}} >{item.name}</td>
                                <td style={{width: "15%"}}>{item.quantity}</td>
                                <td style={{width: "15%"}}>{item.price}</td>
                                <td style={{width: "15%"}}>{item.price * item.quantity}</td>
                            </tr>
                        })
                    }

                </tbody>
                <tfoot className={"i-tfoot"}>
                    <tr>
                        <td colSpan={4}>Итого</td>
                        <td>{order.cost}</td>
                    </tr>
                </tfoot>
            </table>
            <br/>
            <div style={{ width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                <Typography>Менеджер: {order.supervisor}</Typography>
            </div>
            <br/>
        </div>
            <FloatButton
                onClick={()=>{
                    html2canvas(document.getElementById("content-invoice")!).then(canvas => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF({
                            orientation: "p",
                            unit: "pt",
                            format: [canvas.width, canvas.height]
                        });
                        pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
                        pdf.save('sample-file.pdf');
                    })
                }}
                style={{bottom: 100}}
                shape={"circle"}
                type={"primary"}
                icon={<DownloadOutlined />}
            >
            </FloatButton>

            <FloatButton
                onClick={()=>{
                    navigate("/myorders")
                }}
                shape={"circle"}
                type={"primary"}
                icon={<ArrowLeftOutlined />}>
            </FloatButton>
        </div>
    );
};

export default InvoiceComponent;