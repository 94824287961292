import React, { useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Button, Modal, Popconfirm} from "antd";
import {SellItemInterface} from "../../store/Interfaces";
import { DeleteOutlined} from "@ant-design/icons";
import CashItemQuantityModal from "./CashItemQuantityModal";
import {changeCart, changeCartItemPrice, clearCart} from "../../store/mainSlice";
import CashPriceChange from "./CashPriceChange";

const CartTable = (props: {mode: 1 | 2 | 3, active: string, setBlockFocus: any}) => {

    const cashItems:SellItemInterface[] = useSelector((state: RootState) => state.main.cart.items);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPriceChangeOpen, setPriceChangeOpen] = useState(false);

    const currentItem = useRef(0);
    const changeQuantity = useRef(0);
    const [changePrice, setChangePrice] = useState(0);

    const dispatch = useDispatch();

    const showModal = (quantity: boolean) => {
        if(quantity){
            setIsModalOpen(true);
        }else {
            setPriceChangeOpen(true)
        }
        props.setBlockFocus(true)
    };

    const handleOk = () => {
        if(changeQuantity.current !== 0) {
            dispatch(changeCart({
                id: cashItems[currentItem.current].id,
                quantity: changeQuantity.current,
            }))

            props.setBlockFocus(false)
            setIsModalOpen(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        props.setBlockFocus(false)

    };

    const onCancelPrice = ()=>{
        props.setBlockFocus(false)
        setPriceChangeOpen(false)
    }


    console.log("cur: ", currentItem.current)
    return (
        <div id={"tabler-container"} style={{overflow: "auto", height: "calc(100vh - 185px)"}}>
            <table className={"tabler"} style={{width: "calc(100% - 20px)", marginLeft: 10}}>
                <thead>
                <tr>
                    <th style={{color: "white"}}>
                        #
                    </th>
                    <th style={{color: "white"}}>
                        Наименование
                    </th>
                    <th style={{color: "white"}}>
                        Количество
                    </th>
                    <th style={{color: "white"}}>
                        Цена
                    </th>
                    <th style={{color: "white"}}>
                        Итог
                    </th>
                    <th style={{color: "white"}}>
                        <Popconfirm
                            title="Удаление"
                            description="Удалить весь список?"
                            onConfirm={()=>{
                                dispatch(clearCart())
                            }}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Button danger><DeleteOutlined style={{color: "red"}}/></Button>
                        </Popconfirm>

                    </th>
                </tr>

                </thead>

                <tbody>
                {cashItems.map((item, index) => {
                        return <tr className={"table-cash"} id={"table" + item.id} style={{height: 50, background: props.active === item.id ? "#c2ffbe" : ""}} key={index}>
                            <td style={{width: "5%", verticalAlign: "middle", fontSize: 16}} colSpan={1}>{index + 1}</td>
                            <td style={{width: "45%", fontSize: 18, verticalAlign: "middle", textAlign: "left", fontWeight: "bold"}} colSpan={1}>{item.name}</td>
                            <td  style={{width: "20%", padding: 0, verticalAlign: "middle"}} colSpan={1}>
                                <div style={{display: "flex", flexDirection: "row", height: "40px", justifyContent:"center", alignItems: "center"}}>
                                    <div onClick={()=>{
                                        if (item.quantity === 1) return
                                        dispatch(changeCart({
                                            id: item.id,
                                            quantity: item.quantity - 1,
                                        }))
                                    }} className={"cash-quantity"} style={{width: "33%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                                        -
                                    </div>
                                    <div onClick={() => {
                                        showModal(true)
                                        currentItem.current = index
                                    }} className={"cash-quantity"} style={{fontSize: 16, width: "33%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderLeft: "1px solid #9f9f9f",
                                        borderRight: "1px solid " +
                                            "#9f9f9f"}}>
                                        {item.quantity}
                                    </div>
                                    <div onClick={()=>{
                                        dispatch(changeCart({
                                            id: item.id,
                                            quantity: item.quantity + 1,
                                        }))
                                    }} className={"cash-quantity"} style={{width: "33%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                                        +
                                    </div>
                                </div>
                            </td>
                            <td onClick={()=>{
                                showModal(false)
                                setChangePrice(props.mode === 1 ? item.masterPrice : props.mode === 2 ? item.lowPrice : item.highPrice)
                                currentItem.current = index
                            }} className={"cash-quantity"} style={{width: "10%", verticalAlign: "middle", fontSize: 16}} colSpan={1}>{props.mode === 1 ? item.masterPrice + "₸" : ""}{props.mode === 2 ? item.lowPrice +"₸" : ""}{props.mode === 3 ? item.highPrice +"₸" : ""}</td>
                            <td  style={{width: "15%", verticalAlign: "middle", fontSize: 16}} colSpan={1}>
                                {props.mode === 1 ? item.masterPrice * item.quantity + "₸" : ""}
                                {props.mode === 2 ? item.lowPrice * item.quantity + "₸" : ""}
                                {props.mode === 3 ? item.highPrice * item.quantity + "₸" : ""}
                            </td>
                            <td className={"cash-quantity"} onClick={()=>{
                                dispatch(changeCart({
                                    id: item.id,
                                    quantity: 0,
                                }))
                            }} style={{width: "5%", verticalAlign: "middle", fontSize: 16}} colSpan={1}><DeleteOutlined style={{color: "red"}}/></td>
                        </tr>
                    })}
                </tbody>
                <Modal
                    footer={[
                    <Button style={{height: 60, width: 100, marginRight: 30}} onClick={handleCancel}>Отмена</Button>,
                    <Button type={"primary"} style={{height: 60, width: 100}} onClick={handleOk}>OK</Button>]}

                       destroyOnClose open={isModalOpen} title={"Количество: " + (cashItems.length > currentItem.current ? cashItems[currentItem.current].name : "")}
                       onOk={handleOk} onCancel={handleCancel}
                >
                    {
                        cashItems[currentItem.current] &&
                        <CashItemQuantityModal item={cashItems[currentItem.current]} changeQuantity={changeQuantity} cashItemQuantity={0}/>
                    }
                </Modal>

                <Modal destroyOnClose open={isPriceChangeOpen} title={"Цена: " + (cashItems.length > currentItem.current ? cashItems[currentItem.current].name : "")} onCancel={onCancelPrice}
                       footer={[
                           <Button style={{height: 60, width: 100, marginRight: 30}} onClick={()=>{
                               props.setBlockFocus(false)
                               setPriceChangeOpen(false)
                           }}>Отмена</Button>,
                           <Button type={"primary"} style={{height: 60, width: 100}} onClick={()=>{
                               if (changePrice !== 0) {
                                   dispatch(changeCartItemPrice({id: cashItems[currentItem.current].id, low: changePrice, high: changePrice, master: changePrice}))
                               }
                               setPriceChangeOpen(false)
                               props.setBlockFocus(false)
                           }}>OK</Button>]}
                >
                    {
                        cashItems[currentItem.current] && <CashPriceChange cancelPrice={onCancelPrice} origItem={cashItems[currentItem.current]} changePrice={changePrice} setChangePrice={setChangePrice}/>
                    }
                </Modal>
            </table>
        </div>

    );
};

export default CartTable;