import React from 'react';
import {Button, FloatButton, Form, Input, InputNumber, Space} from "antd";
import API from "../../../API/API";
import {setCategories} from "../../../store/mainSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ArrowLeftOutlined} from "@ant-design/icons";

export interface CategoryInterface{
    name: string,
    description: string
}

const AddCategory = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onFinish = (values: any) => {
        API.addCategory(values.name, values.description)
            .then(data=>{
                dispatch(setCategories(data.data))
                alert("Category added")
                navigate("/admin")
            })
            .catch(error=>{
                console.log(error)
                if (error.response.status === 500){
                    alert("Category with this name already exists")
                }
            })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const [form] = Form.useForm()
    return (
        <Space style={{display:"flex", flexDirection: "column", marginTop: 30, width: "100%"}}>
            <FloatButton
                onClick={()=>{
                    navigate("/store")
                }}
                type={"primary"}
                icon={<ArrowLeftOutlined />}>
            </FloatButton>
            <Form
                name="basic"
                form={form}
                style={{ width: "100%" }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<CategoryInterface>
                    label="Наименование"
                    name="name"
                    rules={[{ required: true, message: 'Это обязательное поле' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<CategoryInterface>
                    label="Описание"
                    name="description"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input />
                </Form.Item>


                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Отправить
                    </Button>
                </Form.Item>
            </Form>


        </Space>
    );
};

export default AddCategory;