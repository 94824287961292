import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Select, Typography} from "antd";
import {CategoryInterface} from "../../../store/mainSlice";
import {ItemInterface} from "../../../store/Interfaces";
import {CodeSandboxOutlined, DropboxOutlined} from "@ant-design/icons";

const NextUIPriced = (props: any) => {

    const mainItems = useSelector((state: any)=> state.main.items)
    const categories = useSelector((state: any)=> state.main.categories)
    const [gridView, setGridView] = useState<boolean>(true)

    let x = window.innerWidth
    let div = 4
    if(x < window.innerHeight){
        // x = window.innerHeight
        div = 3
    }

    const [categoryList, setCategoryList] = useState<CategoryInterface[]>([])
    const [searchName, setSearchName] = useState<string>("")

    return (
        <>
            {props.catShow &&
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                <Select mode={"multiple"} allowClear options={categories.map((category: CategoryInterface) => {
                    return {
                        value: category.name,
                        label: category.name
                    }
                })} placeholder="Категории" onChange={(event: string[]) => {
                    setCategoryList(event.map(x => categories.find((y: CategoryInterface) => y.name === x)!))
                }} style={{width: 180}}/>
            </div>
            }
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
                {categories.filter((cat: CategoryInterface) => {
                    if (categoryList.length === 0) {
                        return true
                    }
                    return categoryList.some(list => list.name.toLowerCase() === cat.name.toLowerCase())
                }).map((category: CategoryInterface) => {
                    return (
                        <>
                            <Typography.Title style={{
                                background: "rgba(12,12,12,0.77)",
                                color: "white",
                                padding: 10,
                                borderRadius: 5
                            }} level={5}>{category.name}</Typography.Title>
                            {
                                gridView ?
                                    // <div  style={{width: "100%", display: "grid",  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr"}}>
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center"
                                    }}>
                                        {mainItems.filter((item: ItemInterface) => {
                                                if (searchName !== "") {
                                                    return item.category === category._id && item.name.toLowerCase().includes(searchName.toLowerCase())
                                                }
                                                return item.category === category._id
                                            }
                                        ).map((item: ItemInterface) => {
                                            return (
                                                <div style={{
                                                    borderRadius: 20,
                                                    position: "relative",
                                                    margin: 10,
                                                    width: x / div - 20,
                                                    height: (x / div - 20) * 4 / 3
                                                }}>
                                                    <img
                                                        width={x / div - 20}
                                                        src={item.imageSrc}
                                                        alt={"ss"}
                                                    ></img>
                                                    <div style={{
                                                        position: "absolute",
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        background: "rgba(255,255,255,0.84)",
                                                        textAlign: "center",
                                                        fontSize: 14,
                                                        border: 5
                                                    }}>
                                                        {item.name}
                                                        <br/>
                                                        {item.lowPrice}₸ {item.highPrice}₸
                                                        <br/>
                                                        <CodeSandboxOutlined/> {item.inBlock}
                                                        {" "}
                                                        <DropboxOutlined/> {item.inBox}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div style={{
                                        width: "100%",
                                        display: "grid",
                                        maxWidth: x,
                                        gridTemplateColumns: "1fr"
                                    }}>
                                        {mainItems.filter((item: ItemInterface) => item.category === category._id).map((item: ItemInterface) => {
                                            return <div style={{margin: 3}}>
                                                <img onClick={() => {
                                                    console.log("click image")
                                                }} className={"nui-div-hov"} width={x - 6} src={item.imageSrc}
                                                     alt={"ss"}></img>
                                                <div style={{
                                                    background: "rgba(255,255,255,0.73)",
                                                    textAlign: "center",
                                                    border: 5
                                                }}>
                                                    {item.name}
                                                    <br/>
                                                    Цена {item.price} Оптом {item.lowPrice}
                                                    <br/>
                                                    В блоке {item.inBlock} В коробке {item.inBox}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                            }
                        </>
                    )
                })
                }
            </div>
        </>
    );
};

export default NextUIPriced;