import React, {useEffect, useState} from 'react';
import Items from "./Items";
import {useDispatch, useSelector} from "react-redux";
import API from "../../API/API";
import {CategoryInterface, setCategories, setItems} from "../../store/mainSlice";
import "./StorePage.css"
import Search from "antd/es/input/Search";
import {Button, FloatButton, Modal, Select} from "antd";
import {useNavigate} from "react-router-dom";
import logo from "./logo_transparrent.png"
import NextUI from './NextUI';

const OrdinaryStore = () => {

    const navigate = useNavigate()
    const categories:CategoryInterface[] = useSelector((state: any) => state.main.categories)
    // const count = useSelector((state: any)=>state.main.cart.items.length)
    const user = useSelector((state: any)=>state.main.user)
    const dispatch = useDispatch()

    const [open, setOpen] = React.useState(false);

    useEffect(()=>{
        if(user){
            navigate("/store")
        }else {
            // if(categories.length === 0){
            console.log("updating")
            API.loadCategories()
                .then((data) => {
                    // console.log(data.data)
                    if (data.data.length === 0) return
                    dispatch(setCategories(data.data))
                    return API.getItems()
                }).then(itemsData => {
                if (itemsData === undefined) return
                // console.log("itemsData: ", itemsData)
                dispatch(setItems(itemsData.data))
            })
                .catch(reason => {
                    console.log("reason: ", reason)
                })
            // }
        }
    }, [dispatch, navigate, user])

    const [categoryList, setCategoryList] = useState<CategoryInterface[]>([])
    const [searchName, setSearchName] = useState<string>("")
    const cost = useSelector((state: any)=>{
        let sum = 0
        state.main.cart.items.forEach((item: any)=>{
            sum += item.quantity * item.highPrice
        })
        return sum
    })

    const [nextUI, setNextUI] = useState<boolean>(true)

    return (
        <div className={"pattern-container"}

            style={{
                background: "#F0F1F2"
            }}
        >
            {/*<div className={"pattern"}/>*/}
            {/*<div className={"pattern-upper"}/>*/}
            <div style={{flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                {/*<Typography.Title level={3} style={{textAlign: "center"}}>Шынар канцелярия</Typography.Title>*/}
                {/*<div>*/}
                <Button onClick={() => {
                    setOpen(true)
                }}>Как добраться?</Button>

                <img alt={"logo"} width={100} height={100} src={logo}/>
                <Button onClick={() => {
                    navigate("/login", {replace: true})
                }} type={"primary"} style={{marginRight: 30}}>
                    Войти
                </Button>


                {/*<Button type={"primary"}>*/}
                {/*    Регистрация*/}
                {/*</Button>*/}
                {/*</div>*/}
            </div>
            {nextUI ?
                <NextUI/> :
            <div style={{width: 'calc(100%)', height: "calc(100vh - 100px)", marginLeft: "0px"}}>
                <div style={{
                    marginTop: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                    marginBottom: 10
                }}>

                    <Select mode={"multiple"} allowClear options={categories.map(category => {
                        return {
                            value: category.name,
                            label: category.name
                        }
                    })} placeholder="Категории" onChange={(event: string[])=>{
                        setCategoryList(event.map(x=>categories.find(y=>y.name === x)!))
                    }} style={{ width: 180, marginTop: 10 }} />
                    <Search placeholder="Поиск" style={{width: 160, marginTop: 10}} onChange={event => {
                        setSearchName(event.target.value)
                    }} onSearch={(value) => {
                        setSearchName(value)
                    }}/>
                </div>
                        <Items level={3} categories={categoryList} searchName={searchName}/>
                {
                    cost > 0 &&
                    <>
                        <FloatButton
                            style={{width: 200}}
                            shape={"square"}
                            description={"Сумма: " + cost + "₸"}
                            type={"default"}
                        >
                        </FloatButton>

                    </>
                }
            </div>
            }

            <Modal title={"Кентауская трасса 42, г. Туркестан"} open={open} onCancel={()=>{
                setOpen(false)
            }}
                   okText={"Позвонить"}
                   cancelText={"Отмена"}
                   onOk={()=>{
                       window.open("tel:+77774683060")
                   }}
            >

                <div style={{position: "relative", overflow: "hidden"}}>
                    {/*<a href="https://yandex.ru/maps/org/shynar/51333198179/?utm_medium=mapframe&utm_source=maps"*/}
                    {/*   style={{color: "#eee", fontSize: 12, position: "absolute", top: 0}}>Шынар</a>*/}
                    {/*<a href="https://yandex.ru/maps/21106/turkestan/category/supermarket/184108079/?utm_medium=mapframe&utm_source=maps"*/}
                    {/*   style={{color: "#eee", fontSize: 12, position: "absolute", top: 14}}>Супермаркет в Туркестане</a>*/}
                    <iframe
                        src="https://yandex.ru/map-widget/v1/?ll=68.318323%2C43.326130&mode=search&oid=51333198179&ol=biz&z=18.45"
                        width="560" height="400" frameBorder="1" allowFullScreen
                        style={{position: "relative"}}></iframe>
                </div>
            </Modal>
        </div>
    );
};

export default OrdinaryStore;