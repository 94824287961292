import axios from "axios"
import {AddItemInterface, ItemInterface, SellItemInterface} from "../store/Interfaces";
import {AddUserInterface} from "../main/admin/addUser/AddUser";
const API_URL = process.env.REACT_APP_API_URL
const API = {
    login: (username: string, password: string)=>{
        return axios.post(API_URL + "auth/", {username, password})
    },
    checkAdmin: ()=>{
        return axios.get(API_URL + "auth/isAdmin", {headers: {authorization: localStorage.getItem("token")}})
    },
    getAdministrativeParams: ()=>{
        return axios.get(API_URL + "auth/params", {headers: {authorization: localStorage.getItem("token")}})
    },
    loadCategories: ()=>{
        return axios.get(API_URL + "categories")
    },
    addCategory: (name: string, description: string)=>{
        return axios.post(API_URL + "categories", {name, description}, {headers: {authorization: localStorage.getItem("token")}})
    },
    addItem: (itemData: AddItemInterface)=>{
        return axios.post(API_URL + "items", itemData, {headers: {authorization: localStorage.getItem("token")}})
    },
    makeOrder(orderData: any){
        return axios.post(API_URL + "orders", orderData, {headers: {authorization: localStorage.getItem("token")}})
    },
    getMyOrders(type: "user" | "admin"){
        if(type === "user") {
            return axios.get(API_URL + "orders", {headers: {authorization: localStorage.getItem("token")}})
        }else {
            return axios.get(API_URL + "orders/pending", {headers: {authorization: localStorage.getItem("token")}})
        }
    },
    setOrderStatus(id: string, status: string, name:string){
        return axios.post(API_URL + "orders/setorderstatus/", {status, id, supervisor: name}, {headers: {authorization: localStorage.getItem("token")}})
    },
    getAdminNotPendingOrders(){
        return axios.get(API_URL + "orders/notpending", {headers: {authorization: localStorage.getItem("token")}})
    },
    getAdminicItems(){
        return axios.get(API_URL + "items/adminic", {headers: {authorization: localStorage.getItem("token")}})
    },
    getUserItems(){
        return axios.get(API_URL + "items/userprice", {headers: {authorization: localStorage.getItem("token")}})
    },
    getItems(){
        return axios.get(API_URL + "items")
    },
    createUser(itemData: AddUserInterface){
        return axios.post(API_URL + "auth/adduser", itemData, {headers: {authorization: localStorage.getItem("token")}})
    },
    changeItems(items: ItemInterface[]){
        return axios.post(API_URL + "items/update", {items: items}, {headers: {authorization: localStorage.getItem("token")}})
    },
    deleteItem(id: string){
        return axios.post(API_URL + "items/delete", {id: id}, {headers: {authorization: localStorage.getItem("token")}})
    },
    createReceipt(cartItems: SellItemInterface[] | {price: number, name: string, quantity: number}[], cash: number, uni: String, mode: number, margin: number, comments = "" ){
        return axios.post(API_URL + "receipts", {
            cartItems: cartItems.map((item)=>{
                if("price" in item){
                    return item
                }
                return {name: item.name, price: mode === 1 ? item.masterPrice : mode === 2 ? item.lowPrice : item.highPrice, quantity: item.quantity}
            }),
            cash: cash,
            uni: uni,
            margin,
            type: mode,
            comments
        }, {headers: {authorization: localStorage.getItem("token")}})
    },
    getReceipts(){
        return axios.get(API_URL + "receipts", {headers: {authorization: localStorage.getItem("token")}})
    },
    getBarcodeMaps(){
        return axios.get(API_URL + "barcodemaps", {headers: {authorization: localStorage.getItem("token")}})
    },
    addBarcodeMap(primary: string, secondary: string[]){
        return axios.post(API_URL + "barcodemaps", {primary, secondary}, {headers: {authorization: localStorage.getItem("token")}})
    }

}
export default API

// @ts-ignore
// window.addCat = API.addCategory