import React from 'react';
import {Button, FloatButton, InputNumber, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {LeftOutlined} from "@ant-design/icons";

const CheckPrice = () => {
    const navigate = useNavigate();

    const [sklad, setSklad] = React.useState<string>("Байи");
    const [yue, setYue] = React.useState<number>();
    const [quantity, setQuantity] = React.useState<number>();
    const [volume, setVolume] = React.useState<number>();



    return (
        <div>
            <FloatButton
                icon={<LeftOutlined />}
                onClick={() => {navigate("/admin")}}>
            </FloatButton>

            <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column", height: '30vh', marginTop: "40px"}}>
                <Button onClick={()=>{
                    if(sklad === "Байи") {
                        setSklad("Рома");
                    }else {
                        setSklad("Байи")
                    }
                }} type="primary"> {sklad} </Button>
            <InputNumber
                placeholder={"Цена"}
                value={yue}
                onChange={(e)=>{
                    if(e === null) return;
                    setYue(e)
                }}/>
            <InputNumber
                placeholder={"Количество"}
                value={quantity}
                onChange={(e)=>{
                    if(e === null) return;
                    setQuantity(e)
                }}
            />
            <InputNumber
                placeholder={"Объем"}
                value={volume}
                onChange={(e)=>{
                    if(e === null) return;
                    setVolume(e)
                }}/>

                {sklad === "Байи" ? yue && volume && quantity ? <Button>{(yue*71 + (volume*230*483 + 1000)/quantity).toFixed(2)}</Button>: <></>
                    : yue && volume && quantity ? <Button>{(yue*71 + (volume*170*483 + 1000)/quantity).toFixed(2)}</Button>: <></>
                }

            </div>

        </div>
    );
};

export default CheckPrice;