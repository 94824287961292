import React, {useState} from 'react';
import {FormInstance, GetProp, message, notification, Upload, UploadProps} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import AWS from "aws-sdk";
import {UploadRequestOption as RcCustomRequestOptions} from "rc-upload/lib/interface";
import {AddItemInterface} from "../../../store/Interfaces";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {NotificationType} from "aws-sdk/clients/ses";


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const Uploader = (props: {name: string, category: string, disabled: boolean, form: FormInstance}) => {

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();

    const navigate = useNavigate()
    const key = useSelector((state: any) => {
        if(!state.main.administrative){
            navigate("/store", {replace: true})
            return
        }
        return state.main.administrative.key
    })
    const secret = useSelector((state: any) => {
        if(!state.main.administrative){
            navigate("/store", {replace: true})
            return
        }
        return state.main.administrative.secret
    })

    const handleChange: UploadProps['onChange'] = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj as FileType, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Загрузить</div>
        </button>
    );

    const uploadImage = async (options: RcCustomRequestOptions) => {
        const { onSuccess, onError, file, onProgress } = options;
        const  fileType = file as FileType
        const str = fileType.type === "image/jpeg" ? ".jpg" : ".png"

        const S3_BUCKET = "shynarstore";
        const REGION = "ap-southeast-1";

        AWS.config.update({
            accessKeyId: key,
            secretAccessKey: secret,
        });
        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            Bucket: S3_BUCKET,
            Key: props.category + "/" + props.name.replace("+", "плюс") + str,
            Body: file,
        };

        s3.putObject(params)
            .on("httpUploadProgress", (evt) => {
                if (onProgress) {
                    onProgress({percent: (evt.loaded * 100) / evt.total})
                }
                console.log("pp: ", (evt.loaded * 100) / evt.total)
            })
            .promise()
            .then((data) => {
                const url = "https://shynarstore.s3.ap-southeast-1.amazonaws.com/" + props.category + "/" + props.name.replace("+", "плюс") + str;
                props.form.setFieldValue("imageSrc", url)
                if (onSuccess) {
                    onSuccess("ok")
                }
                openNotificationWithIcon()
        }).catch(err=>{
            console.log(err)
            if (onError) {
                onError(err)
            }
        });
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = () => {
        api["success"]({
            message: 'Внимание',
            description:
                'Изображение загружено успешно',
            placement: 'top',
        });
    };


    return (
        <>
            {contextHolder}
            <Upload
                disabled={props.disabled}
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadImage}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        </>
    );
};

export default Uploader;