import React, {SetStateAction, useState} from 'react';
import {Button, Checkbox, ConfigProvider} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {changeCartByCode} from "../../store/mainSlice";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

const FastCashComp = ( props: {setFastCashModalOpen: SetStateAction<any>, selectedItem: string[], setSelectedItems: SetStateAction<any>}) => {
    const FastCashJSON: {category: string, id: string, items: {
            id: string,
            name: string,
            price: number,
            barcode: string,
            imageSrc?: string
        }[]}[] = useSelector((state: any) => state.main.fastItems)
    const [macro, setMacro] = React.useState<number | null>(null);

    const selectedItems = props.selectedItem, setSelectedItems = props.setSelectedItems
    const [multiSelect, setMultiSelect] = useState<boolean>(false)

    const dispatch = useDispatch();
    return (
        <div id="fast-wrapper" style={{width: "100%", height: "80vh", overflowY: "auto"}}>
            {
                macro === null ?
                    FastCashJSON.map((category, index)=>{
                        return (
                            < ConfigProvider
                                theme={{
                                    components: {
                                        Button: {
                                            /* here is your component tokens */
                                            defaultBorderColor: "#001529"
                                        },
                                    },
                                }}
                            ><Button
                                key={category.id}
                                onClick={() => {setMacro(index)}}
                                style={{margin: 10, height: 100, width: 200}}
                            >
                                <span style={{color: "red", position: "absolute", top: 0, left: 5}}>{index + 1 }</span>
                                <p style={{
                                    display: "table-cell",
                                    whiteSpace: "pre-wrap",
                                    height: 80,
                                    width: 180,
                                    overflow: "hidden",
                                    fontWeight: "bold",
                                    verticalAlign: "middle",
                                    textAlign: "center"
                                }}>{category.category}</p>
                            </Button>
                            </ConfigProvider>
                        )
                    })
                    :
                    FastCashJSON[macro].items.map((item, index)=>{
                        let checked = selectedItems.indexOf(item.id)
                        return < ConfigProvider
                            theme={{
                                components: {
                                    Button: {
                                        /* here is your component tokens */
                                        defaultBorderColor: "#001529"
                                    },
                                },
                            }}
                            ><Button key={item.id} onClick={()=>{
                                if(multiSelect) {
                                    const array = Object.assign([], selectedItems)
                                    if(checked !== -1) {
                                        array.splice(checked, 1);
                                    }else {
                                        array.push(item.id)
                                    }
                                    setSelectedItems(array)
                                }else {
                                    dispatch(changeCartByCode({code: item.id, isID: true}))
                                    props.setFastCashModalOpen(false)
                                }
                            }} style={{ margin: 10, height: 100, width: 200, border:"1px solid black" }}>
                                <span style={{color: "red", position: "absolute", top: 0, left: 5}}>{index + 1 }</span>
                                <span style={{color: "red", position: "absolute", bottom: 0, right: 5}}>{item.price}₸</span>
                                <input checked={checked !== -1} type={"checkbox"} style={{position: "absolute", top: 0, right: 5, width: 25, height: 25}} onClick={(e) => {
                                    e.stopPropagation()
                                    if(!multiSelect) {
                                        setMultiSelect(true)
                                    }
                                    const array = Object.assign([], selectedItems)
                                    if(checked !== -1) {
                                        array.splice(checked, 1);
                                    }else {
                                        array.push(item.id)
                                    }
                                    setSelectedItems(array)
                                }}/>
                                <p style={{
                                    fontWeight: "bold",
                                    display: "table-cell",
                                    whiteSpace: "pre-wrap",
                                    height: 80,
                                    width: 180,
                                    overflow: "hidden",
                                    verticalAlign: "middle",
                                    textAlign: "center"
                                }}>{item.name}</p>
                            </Button>
                        </ConfigProvider>
                    })
            }

            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", position: "absolute", bottom: 10, width: "100%", height: 50}}>
                <Button style={{marginRight: 20}} size={"large"} type={"primary"} onClick={()=>{
                    document.getElementById("fast-wrapper")!.scrollTo({top: document.getElementById("fast-wrapper")!.scrollTop - 240, behavior: "smooth"})
                }}> <ArrowUpOutlined/>  </Button>

                {
                    typeof macro === 'number' && <Button style={{marginRight: 20}} size={"large"} type={"primary"} onClick={()=>{setMacro(null)}}>
                            Назад
                        </Button>

                }
                <Button size={"large"} type={"primary"} onClick={()=>{
                    document.getElementById("fast-wrapper")!.scrollTo({top: document.getElementById("fast-wrapper")!.scrollTop + 240, behavior: "smooth"})

                }}><ArrowDownOutlined/> </Button>

            </div>

        </div>
    );
};

export default FastCashComp;