import React from 'react';
import {ItemInterface} from "../../store/Interfaces";
import {useSelector} from "react-redux";
import Item from "../home/Item";

const CartItems = () => {
    const cartItems:any[] = useSelector((state: any)=>state.main.cart.items)
    const items:ItemInterface[] = useSelector((state: any)=>state.main.items)
    const level = useSelector((state: any)=>{
        if(state.main && state.main.user && state.main.user.level) {
            return state.main.user.level
        }
        return 2
    })
    return (
        <div style={{width: "100%", height: "100%", marginTop: 0, overflowY: "auto"}}>
            <div style={{width: "100%", marginTop: 50, display: "flex", flexWrap:"wrap", justifyContent: "center"}}>

                {cartItems.map(x=>{
                    return <Item level={level} key={x.id} item={items.find(item => x.id === item.id)!}/>
                })}
                <div style={{height: 30}}/>
            </div>

        </div>
    );
};

export default CartItems;