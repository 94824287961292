import React from 'react';
import {DeleteOutlined} from "@ant-design/icons";
import {Button, Card, Popover} from "antd";
import {AdminItemInterface} from "../../store/Interfaces";
import API from "../../API/API";
const AdminItem = (props: {item: AdminItemInterface }) => {

    return (
        <Card
            key={props.item.id}
            title={props.item.name}
            actions={[
                <Button danger>
                    Не осталось
                </Button>,
                <Button>
                    Изменить
                </Button>
            ]}
            extra={
                <Popover content={<div>
                    Удалить предмет?
                    <Button
                        onClick={()=>{
                            API.deleteItem(props.item.id)
                                .then(data=>{
                                    if (data.status === 200){
                                        alert("обнови")
                                    }
                                }).catch(error=>{
                                    console.log("error: ", error)
                                })
                        }}
                    >
                        <DeleteOutlined />
                    </Button>
                </div>}>
                    <Button danger={true} >
                        <DeleteOutlined />
                    </Button>
                </Popover>}
            style={{margin: 10, userSelect: "none", width: 300}}>
            <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{userSelect: "none", width: "50%", justifyContent: "center", display: "flex", alignItems: "center"}}>
                    <img loading={"lazy"} style={{borderRadius: 10}} height={150}
                         // src={Math.random()>0.5 ? wb : ww}
                        src={props.item.imageSrc}
                         alt={"ss"}/>
                </div>
                <div style={{userSelect: "none", width:"55%", marginLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center"}}>
                    <span style={{fontStyle: "italic", fontSize: 13}}>Себестоимость {props.item.price}₸</span>
                    <span style={{fontStyle: "italic", fontSize: 13}}>Супер Цена {props.item.masterPrice}₸</span>
                    <span style={{fontStyle: "italic", fontSize: 13}}>Оптом {props.item.lowPrice}₸</span>
                    <span style={{fontStyle: "italic", fontSize: 13}}>Розница {props.item.highPrice}₸</span>
                </div>
            </div>

        </Card>
    );
};

export default AdminItem;