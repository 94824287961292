import React, {useEffect} from 'react';
import {Button, FloatButton, Space} from "antd";
import {ArrowLeftOutlined, PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import API from "../../API/API";
import {useDispatch} from "react-redux";
import {setSecrets} from "../../store/mainSlice";

const Admin = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        API.checkAdmin()
            .then(()=>API.getAdministrativeParams())
            .then((data:any)=>{
                console.log(data.data)
                dispatch(setSecrets(data.data))
            })
            .catch(error=>{
                console.log(error)
                navigate("/store")
        })
    })

    return (
        <div>

            <FloatButton
                onClick={()=>{
                    navigate("/store")
                }}
                type={"primary"}
                icon={<ArrowLeftOutlined />}>
            </FloatButton>

            <Space style={{display:"flex", flexDirection:"column", marginTop: 50, width: "100%"}}>

                <Button onClick={()=>{
                    navigate("/admin/addItem")
                }}>
                    <PlusOutlined />
                </Button>

                <Button onClick={()=>{
                    navigate("/admin/addCategory")
                }}>
                    New Category
                </Button>

                <Button>
                   Sdelki
                </Button>
                <Button>
                    Sklad
                </Button>
                <Button onClick={()=>{
                    navigate("/admin/getPricedImages")
                }}>
                    Ценовые фото
                </Button>
                <Button onClick={()=>{
                    navigate("/admin/addUser")
                }}>
                    Добавить клиента
                </Button>

                <Button onClick={()=>{
                    navigate("/admin/itemsTable")
                }}>
                    Таблица товаров
                </Button>
                <Button onClick={()=>{
                    navigate("/admin/barcoder")
                }}>
                    Баркодер
                </Button>
                <Button onClick={()=>{
                    navigate("/admin/checkPrice")
                }}>
                    FastAdd
                </Button>
                <Button onClick={()=>{
                    navigate("/admin/barcodemap")
                }}>
                    Barcode Map
                </Button>
            </Space>
        </div>
    );
};

export default Admin;