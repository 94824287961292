import React, {useCallback, useEffect, useState} from 'react';
import Navigator from "../Navigator/Navigator";
import {useDispatch, useSelector} from "react-redux";
import API from "../../API/API";
import {OrderInterface, setMyOrders, setNotPendingOrders, setUpdateOrders} from "../../store/mainSlice";
import Orders from "./Orders";
import {FloatButton, Menu, MenuProps} from "antd";
import {AppstoreOutlined, MailOutlined, ReloadOutlined, SettingOutlined} from "@ant-design/icons";

const MyOrders = (props: {type: "user" | "admin"}) => {

    const myOrders = useSelector((state: any)=>state.main.user.orders)
    const adminNotPendingOrders = useSelector((state: any)=>state.main.user.notPendingOrders)
    const isAdmin = useSelector((state: any)=>state.main.user.isAdmin)
    const needUpdateOrders = useSelector((state: any)=>state.main.needUpdateOrders)
    const dispatch = useDispatch()


    const updateOrders = useCallback(()=>{
        API.getMyOrders(isAdmin ? "admin": "user").then(data => {
            console.log("fetched orders: ", data.data)
            dispatch(setMyOrders(data.data.reverse().map((order: any) => {
                return {
                    id: order._id,
                    cost: order.cost,
                    date: order.date,
                    cartItems: order.cartItems,
                    deliveryAddress: order.deliveryAddress,
                    status: order.status,
                    comments: order.comments,
                    supervisor: order.supervisor,
                    supervisorID: order.supervisorID,
                    ordererName: order.ordererName
                }
            })))
            dispatch(setUpdateOrders(false))
        }).catch(error => {
            console.log("err: ", error)
        })
        if(isAdmin) {
            API.getAdminNotPendingOrders().then((data) => {
                console.log("data")
                dispatch(setNotPendingOrders(
                    data.data.reverse().map((order: any) => {
                    return {
                        id: order._id,
                        cost: order.cost,
                        date: order.date,
                        cartItems: order.cartItems,
                        deliveryAddress: order.deliveryAddress,
                        status: order.status,
                        comments: order.comments,
                        supervisor: order.supervisor,
                        supervisorID: order.supervisorID,
                        ordererName: order.ordererName
                    }
                })))
                dispatch(setUpdateOrders(false))
            })
        }
    }, [dispatch, isAdmin])



    useEffect(()=>{
        console.log("mm", myOrders)
        if(!myOrders || myOrders.length === 0 || needUpdateOrders) {
            updateOrders()
        }
    }, [myOrders, needUpdateOrders, updateOrders])



    return (
        <div >
            <Navigator/>
            <Orders type={isAdmin ? "admin" : "user"} updateOrders={updateOrders} adminic={adminNotPendingOrders}/>
            <FloatButton
                type={"primary"}
                onClick={()=>{
                    updateOrders()
                }}
                icon={<ReloadOutlined />}
                shape={"circle"}
            />
        </div>
    );
};

export default MyOrders;