import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {OrderInterface} from "../../store/mainSlice";
import {Button, Card, Menu, MenuProps, Popconfirm, Typography} from "antd";
import {
    FileDoneOutlined, FundProjectionScreenOutlined,PlusSquareOutlined
} from "@ant-design/icons";
import OrderCard from "./OrderCard";

const Orders = (props: {type:  "user" | "admin", adminic: OrderInterface[], updateOrders: CallableFunction}) => {
    const myOrders = useSelector((state: any)=>state.main.user.orders)

    const [current, setCurrent] = useState('pending');

    const items: MenuProps['items'] = [
        {
            label: 'Новые Заказы',
            key: 'pending',
            icon: <PlusSquareOutlined />,
        },
        {
            label: 'В процессе',
            key: 'working',
            icon: <FundProjectionScreenOutlined />,
        },
        {
            label: 'Выполненные',
            key: 'done',
            icon: <FileDoneOutlined />,
        }
    ];


    return (
        <div style={{width: "calc(100% - 50px)",  backgroundColor:"#F0F2F5", height: "calc(100vh - 50px)", overflowY: "auto", marginLeft: 50, marginTop: 50, flexDirection: "column", display: "flex", alignItems: "center"}}>
            <Menu onClick={(e)=>{
                setCurrent(e.key)
            }} style={{marginTop: 0, marginLeft: 0, width: "100%", justifyContent: "center"}} selectedKeys={[current]} mode="horizontal" items={items} />
            {/*{*/}
            {/*    current === "pending" &&*/}
            {/*    myOrders && myOrders.map((order: OrderInterface, index: number)=>{*/}
            {/*        return <OrderCard type={props.type} key={index} order={order} index={index}/>*/}
            {/*    })*/}
            {/*}*/}
            {/*{*/}
            {/*    // current === "working" &&*/}
            {   myOrders && myOrders.filter((order: OrderInterface)=> order.status === current).map((order: OrderInterface, index: number)=>{
                return <OrderCard updateOrders={props.updateOrders} type={props.type} key={index} order={order} index={index}/>
            })
            }
            {
                props.adminic && props.adminic.filter((order)=> order.status === current).map((order: OrderInterface, index: number)=>{
                    return <OrderCard updateOrders={props.updateOrders} type={props.type} key={index} order={order} index={index}/>
                })
            }
            {/*// }*/}


        </div>
    );
};

export default Orders;