import React, {useCallback} from 'react';
import Item from "./Item";
import {ItemInterface} from "../../store/Interfaces";
import {useSelector} from "react-redux";
import {CategoryInterface} from "../../store/mainSlice";
import {Button} from "antd";
import { Virtuoso } from 'react-virtuoso'
import './Items.css'

const Items = (props: {categories: CategoryInterface[], searchName: string, level: 1 | 2 | 3}) => {
    const categories = useSelector((state: any) => state.main.categories)
    const items:ItemInterface[] = useSelector((state: any) => state.main.items)

    const searcher = useCallback((category: CategoryInterface)=>{
        console.log("ss: ", category)
        console.log("items: ", items)
        const itemRender = items
            .filter(item => item.category === category._id && item.name.toLowerCase().includes(props.searchName.toLowerCase()))
            .map(x=>{return <Item level={props.level} key={x.id} item={x}/>})

        if(itemRender.length === 0){
            // return <></>
        }
        return <div key={category.name} style={{width: "100%", marginTop: 0, display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
            <Button size={"large"} type={"text"}>{category.name}</Button>
            <div style={{width: "100%", marginTop: 0, display: "flex", flexWrap:"wrap", justifyContent: "center", position: "relative"}}>
                {itemRender}
            </div>

        </div>
    }, [items, props.level, props.searchName])

    return (
        <Virtuoso data={categories} itemContent={(index, category, context)=>{
            console.log("len: ", props.categories.length)
            if(props.categories.length > 0) {
                let found = false
                props.categories.forEach(cat => {
                    if (cat._id === category._id){
                        found = true
                    }
                })
                if (!found){
                    return
                }
            }
            const itemRender = items
                .filter(item => item.category === category._id && item.name.toLowerCase().includes(props.searchName.toLowerCase()))
                .map(x=>{return <Item level={props.level} key={x.id} item={x}/>})
            if(itemRender.length === 0){
                return <></>
            }
            return <div key={category.name} style={{width: "100%", marginTop: 0, display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                <Button size={"large"} type={"text"}>{category.name}</Button>
                <div style={{width: "100%", marginTop: 0, display: "flex", flexWrap:"wrap", justifyContent: "center", position: "relative"}}>
                    {itemRender}
                </div>

            </div>
        }} ></Virtuoso>
            // <div style={{ overflowY: "auto", overflowX: "hidden", height: "88%", width: "100%"}}>
            //     {
            //         props.categories.length === 0 ?
            //             categories.map((category: CategoryInterface)=>searcher(category))
            //             :
            //             props.categories.map((category: CategoryInterface)=>searcher(category))
            //     }
            //     <div style={{height: 150, position: "relative", width: "100%"}}/>
            // </div>

    );
};

export default Items;