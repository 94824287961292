import React, {useState} from 'react';
import {Button, Checkbox, FloatButton, Form, Input, Select, SelectProps, Space} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import API from "../../../API/API";
import {md5} from "js-md5";
const options: SelectProps['options'] = [];

const handleChange = (value: string) => {
    console.log(`selected ${value}`);
};

export interface AddUserInterface{
    username: string;
    password: string,
    name: string,
    phone: string,
    address: string[],
    isAdmin: boolean,
    level: number
}

const AddUser = () => {

    const navigate = useNavigate()

    const onFinish = (values: any) => {
        // console.log(values)
        let newValues = {...values, password: md5(values.password), isAdmin: !!values.isAdmin}
        console.log("nn: ", newValues)
        setLoading(true)
        API.createUser(newValues)
            .then(data=>{
                console.log(data)
                navigate("/admin")
            }).catch(error => {
                setLoading(false)
                console.log(error)
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <Space style={{display:"flex", flexDirection: "column", marginTop: 30, width: "100%"}}>
            <FloatButton
                onClick={()=>{
                    navigate("/admin")
                }}
                type={"primary"}
                icon={<ArrowLeftOutlined />}>
            </FloatButton>
            <Form
                onKeyDown={event => {
                    if (event.key === "Enter") {
                        event.preventDefault()
                    }
                }}
                name="basic"
                form={form}
                style={{ width: "100%" }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<AddUserInterface>
                    label="Логин"
                    name="username"
                    rules={[{ required: true, message: 'Это обязательное поле' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<AddUserInterface>
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: 'Это обязательное поле' }]}
                >
                    <Input type="password" />
                </Form.Item>

                <Form.Item<AddUserInterface>
                    label="Имя"
                    name="name"
                    rules={[{ required: true, message: 'Это обязательное поле' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<AddUserInterface>
                    label="Телефон"
                    name="phone"
                    rules={[{ required: true, message: 'Это обязательное поле' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<AddUserInterface>
                    label="Адреса"
                    name="address"
                    // rules={[{ required: true, message: 'Это обязательное поле' }]}
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        onChange={handleChange}
                        tokenSeparators={[',']}
                        options={options}
                    />
                </Form.Item>

                <Form.Item<AddUserInterface>
                    label="Уровень"
                    name="level"
                >
                    <Input />
                </Form.Item>

                <Form.Item<AddUserInterface>
                    label="Администратор"
                    name="isAdmin"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>


                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button loading={loading} disabled={loading} type="primary" htmlType="submit">
                        Отправить
                    </Button>
                </Form.Item>
            </Form>


        </Space>
    );
};

export default AddUser;