import React, {useCallback} from 'react';
import {AdminItemInterface} from "../../store/Interfaces";
import {useSelector} from "react-redux";
import {CategoryInterface} from "../../store/mainSlice";
import {Button} from "antd";
import './Items.css'
import AdminItem from "./AdminItem";

const AdminItems = (props: {categories: CategoryInterface[], searchName: string}) => {
    const categories = useSelector((state: any)=>state.main.categories)
    const items:AdminItemInterface[] = useSelector((state: any)=>state.main.items)

    const searcher = useCallback((category: CategoryInterface)=>{
        const filtered = items.filter(item => item.category === category._id && item.name.toLowerCase().includes(props.searchName.toLowerCase())).map(x=>{
            return <AdminItem key={x.id} item={x}/>
        })
        if(filtered.length === 0){
            return <></>
        }
        return <div key={category.name} style={{width: "100%", marginTop: 0, display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
            <Button size={"large"} type={"text"}>{category.name}</Button>
            <div style={{width: "100%", marginTop: 0, display: "flex", flexWrap:"wrap", justifyContent: "center", position: "relative"}}>
                {
                    filtered
                }
            </div>

        </div>
    }, [items, props.searchName])

    return (
        <div style={{ overflowY: "auto", overflowX: "hidden", height: "88%", width: "100%"}}>
            {
                props.categories.length === 0 ?
                    categories.map((category: CategoryInterface)=>searcher(category))
                    :
                    props.categories.map((category: CategoryInterface)=>searcher(category))
            }
            <div style={{height: 150, position: "relative", width: "100%"}}/>
        </div>
    );
};

export default AdminItems;