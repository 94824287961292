import React, {useCallback, useState} from 'react';
import {Button, Card, Input} from "antd";
import API from "../../API/API";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {login, setItems} from "../../store/mainSlice";
import {LoadingOutlined} from "@ant-design/icons";
// import {socket} from "../../socket";
import logo from "../../assets/logo.png"
import {md5} from "js-md5";

const Login = () => {

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<number>(0);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const signIn = useCallback(()=>{
        setLoading(1)
        API.login(username.toLowerCase(), md5(password)).then(data=>{
            console.log(data)
            localStorage.setItem("token", "Bearer " + data.data.token)
            if(data.data.isAdmin) {
                // socket.emit("adminlog", data.data)
                dispatch(login(data.data))
                return API.getAdminicItems()
            }else {
                console.log("log:")
                dispatch(login(data.data))
                return API.getUserItems()
            }
        }).then((itemsData)=>{
            console.log("items: ", itemsData)
            dispatch(setItems(itemsData.data))
            navigate("/store")
        }).catch(reason => {
            console.log("reason: ", reason)
            setLoading(2)
        })
    }, [dispatch, navigate, username, password, setLoading])

    return (
        <div style={{display: "flex",  flexDirection: "column", background:"#FFD100", justifyContent: "start", alignItems: "center", width: "100%", height: "100vh"}}>
            <img src={logo} width={200} style={{marginTop: 100}} alt={"logo"} height={200}/>
            <Card style={{maxWidth: "400px", minWidth: 300 }}>
                <h1>Войти в систему</h1>
                <Input value={username} onChange={e=>{setUsername(e.target.value)}} placeholder={"Пользователь"}/>
                <br/>
                <br/>
                <Input type={"password"} value={password} onKeyPress={e=>{
                    if(e.key === "Enter") {
                        signIn()
                    }
                }} onChange={(e)=>{setPassword(e.target.value)}} placeholder={"Пароль"}/>
                <br/>
                <br/>
                <div style={{display: "flex", }}>
                    <Button  onClick={()=>{
                        navigate("/")
                    }} type={"primary"} style={{marginLeft: "auto"}}>
                        На главную
                    </Button>
                    <Button danger={loading === 2} onClick={()=>{
                        signIn()
                    }} type={"primary"} style={{marginLeft: "auto"}}>
                        {loading  === 0 && "Войти"}
                        {loading  === 1 && <LoadingOutlined/>}
                        {loading  === 2 && "Не удалось"}
                    </Button>


                </div>

            </Card>
        </div>
    );
};

export default Login;