import React, {useCallback} from 'react';
import {CloseCircleOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Card, ConfigProvider, Image, InputNumber, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import { changeCart } from '../../store/mainSlice';
import {ItemInterface, SellItemInterface} from "../../store/Interfaces";

const Item = (props: {item: ItemInterface, level: 1 | 2 | 3}) => {


    const cart = useSelector((state: any)=>state.main.cart)

    const [quantity, setQuantity] = React.useState<number>(
        cart.items.find((x: SellItemInterface)=>x.id === props.item.id)?.quantity || 0
    );

    const inBox = props.item.inBox
    const inBlock = props.item.inBlock


    const dispatch=useDispatch()

    const makeDispatch = useCallback((quantity: number)=>{
        console.log("qu: ", quantity)
        let changeItem = {
            id: props.item.id,
            quantity: quantity,
        }
        console.log("chh: ", changeItem)
        console.log("item: ", props.item)
        dispatch(changeCart(changeItem))
    }, [dispatch, props.item])

    return (
        <ConfigProvider
            theme={{
            components: {
                // Seed Token
                Card:{
                    actionsBg: quantity > 0 ? "#b7eb8f" : ""
                }
            },
        }}
        >
        <Card
            // headStyle={{padding: "0px 10px 0px 20px"}}
            extra={ quantity > 0 &&
                <Button onClick={()=>{
                    setQuantity(0)
                    if(quantity > 0) {
                        makeDispatch(0)
                    }
                }}
                        danger
                        // ghost
                        type={"text"}
                >
                    <CloseCircleOutlined/>
                </Button>
        }
            className={"item-card"}
            key={props.item.id}
            title={props.item.name}
            actions={[
                <MinusOutlined
                    onClick={e=>{
                        // e.preventDefault()
                        setQuantity(val => val === 0 ? 0 : val - 1)
                        if(quantity > 0){
                            makeDispatch(quantity - 1)
                        }
                    }}
                />,
                quantity >= 0 ? <InputNumber value={quantity} onChange={e=> {
                    setQuantity(e ? e : 0)
                    makeDispatch(e ? e : 0)
                }}/> : <></>,
                <PlusOutlined
                    onClick={(e)=> {
                        // e.preventDefault()
                        setQuantity(val => val + 1)
                        makeDispatch(quantity + 1)
                    }}
                />
            ]}

            style={{margin: 10, userSelect: "none",
        }}>
            <div style={{ display: "flex", flexDirection: "row"}}>
                <div style={{userSelect: "none", width: "45%", justifyContent: "center", display: "flex", alignItems: "center"}}>
                    <Image loading={"lazy"} style={{borderRadius: 10}} width={120}
                         // src={Math.random()>0.5 ? wb : ww}
                         src={props.item.imageSrc}
                         alt={"ss"}/>
                </div>
                <div style={{userSelect: "none", marginLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center"}}>
                    {/*<span style={{fontWeight: "bold", fontSize: 18, }}>{props.item.name}</span>*/}
                    <span style={{fontStyle: "italic", fontSize: 16}}>{props.level === 2 ? props.item.highPrice : props.item.lowPrice}₸</span>
                    {
                        inBox &&
                        <Button
                            style={{marginTop: 10}}
                            onClick={(e) => {
                                setQuantity(val => val + inBox)
                                makeDispatch(quantity + inBox)
                            }}
                        >Коробка {inBox}шт +</Button>
                    }
                    {
                        inBlock &&
                        <Button
                            style={{marginTop: 10}}
                            onClick={(e) => {

                                setQuantity(val => val + inBlock)
                                makeDispatch(quantity + inBlock)
                            }}
                        >Блок {inBlock}шт +</Button>
                    }
                    {/*{*/}
                    {/*    quantity > 0 ?*/}
                    {/*    <Button onClick={e => {*/}
                    {/*        setQuantity(0)*/}
                    {/*        if (quantity > 0) {*/}
                    {/*            makeDispatch(0)*/}
                    {/*        }*/}
                    {/*    }} style={{marginTop: 10, display: quantity > 0 ? "block" : "none"}} danger>*/}
                    {/*        <DeleteOutlined />*/}
                    {/*    </Button>*/}
                    {/*        :*/}
                    {/*        <div style={{height: 42}}/>*/}

                    {/*}*/}
                </div>


            </div>
            {/*<div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: 10}}>*/}
            {/*    {*/}
            {/*        inBox &&*/}

            {/*        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>*/}
            {/*            <Button*/}
            {/*                onClick={(e) => {*/}
            {/*                    setQuantity(val => val + inBox)*/}
            {/*                    makeDispatch(quantity + inBox)*/}
            {/*                }}*/}
            {/*            >Коробка +</Button>*/}
            {/*            <Typography.Text code style={{textAlign: "center"}}>{inBox} шт</Typography.Text>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*    {inBlock &&*/}
            {/*        <div style={{display: "flex", flexDirection: "column"}}>*/}
            {/*            <Button*/}
            {/*                onClick={e => {*/}
            {/*                    setQuantity(val => val + inBlock)*/}
            {/*                    makeDispatch(quantity + inBlock)*/}
            {/*                }}*/}
            {/*            >Блок +</Button>*/}
            {/*            <Typography.Text code style={{textAlign: "center"}}>{inBlock} шт</Typography.Text>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</div>*/}

        </Card>
        </ConfigProvider>

    );
};

export default Item;