import React, {useEffect, useState} from 'react';
import Navigator from "../Navigator/Navigator"
import Items from "./Items";
import {useDispatch, useSelector} from "react-redux";
import API from "../../API/API";
import {CategoryInterface, setCategories, setItems} from "../../store/mainSlice";
// import './StorePage2.scss'
import "./StorePage.css"
import Search from "antd/es/input/Search";
import {Button, FloatButton, Select, Switch, Typography} from "antd";
import {ArrowRightOutlined, FormOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import AdminItems from "./AdminItems";
const StorePage = () => {

    const navigate = useNavigate()
    const categories:CategoryInterface[] = useSelector((state: any) => state.main.categories)
    const isAdmin:(boolean | undefined) = useSelector((state: any) => {
        if(state.main.user === undefined) {
            navigate("/")
            return undefined
        }
        else {
            return state.main.user.isAdmin
        }
    })
    if(isAdmin === undefined){
        navigate("/")
    }
    const count = useSelector((state: any)=>state.main.cart.items.length)

    const dispatch = useDispatch()


    const [loading, setLoading] = useState<boolean>(false)

    useEffect(()=>{
        if(categories.length === 0){
            API.loadCategories().then((data)=>{
                console.log(data.data)
                if(data.data.length === 0) return
                dispatch(setCategories(data.data))
            }).catch(reason => {
                console.log("reason: ", reason)
            })
        }
    }, [categories, dispatch])

    const [categoryList, setCategoryList] = useState<CategoryInterface[]>([])
    const [searchName, setSearchName] = useState<string>("")
    const [adminic, setAdminic] = useState<boolean>(false)
    const [high, setHigh] = useState<boolean>(false)

    const cost = useSelector((state: any)=>{
        let sum = 0
        state.main.cart.items.forEach((item: any)=>{
            if(high){
                sum += item.quantity * item.highPrice
            }else {
                sum += item.quantity * item.lowPrice
            }
        })
        return sum
    })

    return (
        <div className={"pattern-container"}
             // style={'width: calc(100% - 50px); height: 100vh; marginLeft: 50px'}
        >
            <div className={"pattern"}/>
            <Navigator/>
            <div style={{width: 'calc(100% - 50px)', height: "calc(100vh - 50px)", marginLeft: "50px"}}>
                <div style={{marginTop: 50, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", width: "100%", marginBottom: 10}}>
                    <Select mode={"multiple"} allowClear options={categories.map(category=>{
                        return {
                            value: category.name,
                            label: category.name
                        }
                    })} placeholder="Категории" onChange={(event: string[])=>{
                        setCategoryList(event.map(x=>categories.find(y=>y.name === x)!))
                    }} style={{ width: 180, marginTop: 10 }} />
                    <Button loading={loading} disabled={loading} onClick={()=>{
                        setLoading(true)
                        if(isAdmin) {
                            API.loadCategories()
                                .then((data)=>{
                                    // console.log(data.data)
                                    if(data.data.length === 0) return
                                    dispatch(setCategories(data.data))
                                    return API.getAdminicItems()
                                })
                                .then(itemsData=>{
                                    if(itemsData === undefined) return
                                    dispatch(setItems(itemsData.data))
                                })
                                .catch(reason => {
                                    console.log("reason: ", reason)
                                })
                                .finally(()=>{
                                    setLoading(false)
                                })
                        }else {
                            API.loadCategories()
                                .then((data)=>{
                                    if(data.data.length === 0) return
                                    dispatch(setCategories(data.data))
                                    return API.getUserItems()
                                })
                                .then(itemsData=>{
                                    if(itemsData === undefined) return
                                    dispatch(setItems(itemsData.data))
                                })
                                .catch(reason => {
                                    console.log("reason: ", reason)
                                })
                                .finally(()=>{
                                    setLoading(false)
                                })
                        }
                    }} style={{marginTop: 10}}><ReloadOutlined/></Button>
                    <Search placeholder="Поиск" style={{width: 160, marginTop: 10}} onChange={event => {
                        setSearchName(event.target.value)
                    }} onSearch={(value) => {
                        setSearchName(value)
                    }}/>
                </div>
                {isAdmin &&
                    <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>

                        <div>
                            <Typography>Админ</Typography>
                            <Switch value={adminic} onChange={(e)=>{setAdminic(e)}}/>
                        </div>
                        {<div><Typography>Оптом</Typography><Switch disabled={adminic} value={high} onChange={(e)=>{setHigh(e)}}/></div>}
                    </div>
                }
                {
                    adminic ?
                        <AdminItems categories={categoryList} searchName={searchName}/>
                        :
                        <Items level={high ? 2 : 1} categories={categoryList} searchName={searchName}/>
                }
                {adminic &&
                    <>
                        <FloatButton
                            onClick={()=>{
                                navigate("/admin/addItem")
                            }}
                            icon={<PlusOutlined/>}
                        >
                        </FloatButton>
                        <FloatButton
                            style={{bottom: 100}}
                            onClick={()=>{
                                navigate("/admin/addCategory")
                            }}
                            icon={<FormOutlined/>}
                        >
                        </FloatButton>
                </>

                }
            {
                cost > 0 &&
                <>

                    <FloatButton
                        style={{width: 200, bottom: 100}}
                        shape={"square"}
                        description={"Сумма: " + cost + "₸"}
                        type={"default"}
                    >
                    </FloatButton>

                    <FloatButton
                        onClick={()=>{
                            navigate("/cart")
                        }}
                        badge={{count}}
                        style={{width: 200}}
                        shape={"square"}
                        description={"Корзина"}
                        type={"primary"}
                        icon={<ArrowRightOutlined />}>
                    </FloatButton>
                </>
            }
            </div>
        </div>
    );
};

export default StorePage;